<template>
  <div>

    <!--       xxxxxxxxxxxxxxxxx         -->

    <div class="containerz">
      <div class="minus" @click="minusQuantity(product, product.uniteDeVente)">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 10H5" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <input class="quantity py-2" v-model="product.quantityTmp"
          @keyup.enter="onEnter"
          :readonly="!userInfos.roles.map(x => x.level).some(roleLevel => roleLevel >= 15)"
          type="number"
          @blur="blurQuantity(product)">
      <div class="plus" @click="plusQuantity(product, product.uniteDeVente)">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5V10M10 10V15M10 10H15M10 10H5" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>

    <!--        xxxxxxxxxxxxxxxxxx          -->

    <div class="d-flex pyz-2" v-if="hasOneOfRoles(['ROLE_SHOWROOM', 'ROLE_MANAGER', 'ROLE_ADMIN']) && false" > <!--  +/- colisage pour reassort -->
      <div style="margin-right: 70px;" class="ml-2">
          <v-btn
            dark
            x-small
            fab
            @click="minusQuantity(product, product.colisage)"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
      </div>
      <div>
          <v-btn
            dark
            fab
            x-small
            @click="plusQuantity(product, product.colisage)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
      </div>
      
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";

//import debounceAsync from 'debounce-async';
//var audio = new Audio('https://aminelearningbucket.s3.eu-west-3.amazonaws.com/sounds/bip.mp3?sdfml')
export default {
  props: ["product"],
  data: () => ({
    oldQuantity: 0,
    quantity: 0,
    pasEnStockSnackBar: false,
  }),
  methods: {
    hasOneOfRoles(roles) {
      //console.log('in has one of roles, userInfos ->', this.userInfos)
      return this.userInfos.roles
      .map(x => x.roleName)
      //.split(',')
      .some(role => roles.includes(role))
    },
    onEnter(event){
      event.target.blur()
    },
    /**/
    blurQuantity(product) {
      //console.log("blured prod->", product);
      if (product.quantityTmp && !isNaN(product.quantityTmp) && product.quantityTmp >= 0 && this.userInfos.roles.map(x => x.level).some(roleLevel => roleLevel >= 15) ) {
        this.playBip();
        this.debouncedPersistPanierV2(product, this)
      } else {
        product.quantityTmp = product.quantity;
      }
    },
    
    plusQuantity(product, qte) {
      if (product.enStock || this.userInfos.roles.map(x => x.level).some(roleLevel => roleLevel >= 15) ) {
        /*document.getElementById("panier").classList.add('animate-panier');
                console.log(document.getElementById("panier").classList)*/
        //this.setPasEnStockSnackBar(false);
        //this.pasEnStockSnackBar = false
        this.playBip();
        product.quantityTmp += qte;
        //product.quantity = product.quantityTmp;
        this.debouncedPersistPanierV2(product, this)

      }
    },
    minusQuantity(product, qte) {
        //this.setPasEnStockSnackBar(false);
        //this.pasEnStockSnackBar = false;*
        console.log('minusQuantity, quantityTmp, quantity, qte ->', product.quantityTmp, this.quantity, qte)
        if (product.quantityTmp >= qte) {
          this.playBip();
          product.quantityTmp -= qte;
          this.debouncedPersistPanierV2(product, this)
        }
    },
    debouncedPersistPanierV2: _.debounce(async (product, context) => {
      console.log('prodz -> ', product)
      try {
        let res = await context.persistPanier({productReference: product.reference, quantity: product.quantityTmp})
        if(res.data) {
          product.quantityTmp = res.data.quantity
          product.quantity = res.data.quantity
          context.updateCartQuantity(res.data);
          console.log('emitting updatedCart')
          context.$emit('updatedCart')
        }
        
      }catch(err) {
        console.log(err)
        //product.quantityTmp = product.quantity
      }
    }, 500)
    ,
    
    
    openPasEnStockSnackBar() {
      this.pasEnStockSnackBar = true;
      this.setPasEnStockSnackBar(true);
      this.debouncedCloseStockSnackbar();
    },
    closeStockSnackbar() {
      this.pasEnStockSnackBar = false;
    },
    
    playBip() {
      this.audio.play();
    },
    ...mapActions(["persistPanier"]),
    ...mapMutations([
      "updateCartQuantity",
      "setPasEnStockSnackBar",
      "setCoordonnesPanier",
      "setDo_animate_panier",
    ]),
  },
  computed: {
    ...mapGetters(["shoppingCart", "pasEnStockSnackBarGeneral", "audio", 'userInfos']),
  },
  watch: {
    /*
    oldQuantity(val) {
      console.log(val);
    },
    pasEnStockSnackBarGeneral(val) {
      console.log("pas");
      if (!val) this.pasEnStockSnackBar = false;
    },*/
  },
  created() {
    //console.log('created panier, userinfos ->', this.userInfos)
    //this.debouncedPersistPanier = _.debounce(this.persistPanier, 500);
    //this.debouncedAsyncPersistPanier = debounceAsync(this.persistPanier, 500);
    this.debouncedPlayBip = _.debounce(this.playBip, 500);
    /*
    this.debouncedCloseStockSnackbar = _.debounce(
      this.closeStockSnackbar,
      3000
    );
    */
    /*console.log('ici')
        var panier = document.getElementsByClassName('panier')[0];
        this.setCoordonnesPanier(panier)*/
  },
};
</script>

<style>

.containerz {
  display: flex;
  widthz: 200px;
  justify-content: center;
  align-items: center;
  gapZ: 38px;
  border-radius: 12px;
  border: 1px solid #E6E6E6;
  borderz: 1px solid red;
}
.minus {
  display: flex;
  width: 56px;
  height: 48px;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-right: 1px solid #E6E6E6;
  cursor: pointer;
  flex-growZ: 2;
}
.quantity {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  max-widthz: 200px;
  flex-growz: 1;
  padding-leftz: 2px;
  outline: none;
  text-align: center;
  backgroundz: yellow;
  min-width: 0;
  flex-growZ: 3;
}

.plus {
  display: flex;
  width: 56px;
  height: 48px;
  padding: 14px 19px 14px 17px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-left: 1px solid #E6E6E6;
  cursor: pointer;
  flex-growZ: 20;
}
.icon {
  display: inline-block;
  width: 20;
  height: 20px;
  background-size: cover;
}
.minus:hover path{
  stroke:red;
}
.plus:hover path{
  stroke:green;
}
.panier.animate-panier {
  /*transition: transform 0.5s linear;*/
  animation: panier-anim 1.5s linear infinite;
}
@keyframes panier-anim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.centered-input input {
  text-align: center;
}
div.disabled {
  pointer-events: none;

  /* for "disabled" effect */
}
.background-red {
  background-color: red;
  color: white !important;
}
.rounded {
  border-radius: 50px;
}
.hovered-mdi-plus {
  color: blue;
}
</style>