<template>
    <div class="" ref="searchRoot">
        <div class="search-header px-3 py-2 elevation-3 ">
            <div class="search-input-wrapper mrZ-5">
                <v-icon v-if="false" class="search-inner-magnify">mdi-magnify</v-icon>
                <svg class="search-inner-magnify" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="search">
                  <path id="Vector" opacity="0.9" d="M21 21.5L15 15.5M17 10.5C17 11.4193 16.8189 12.3295 16.4672 13.1788C16.1154 14.0281 15.5998 14.7997 14.9497 15.4497C14.2997 16.0998 13.5281 16.6154 12.6788 16.9672C11.8295 17.3189 10.9193 17.5 10 17.5C9.08075 17.5 8.1705 17.3189 7.32122 16.9672C6.47194 16.6154 5.70026 16.0998 5.05025 15.4497C4.40024 14.7997 3.88463 14.0281 3.53284 13.1788C3.18106 12.3295 3 11.4193 3 10.5C3 8.64348 3.7375 6.86301 5.05025 5.55025C6.36301 4.2375 8.14348 3.5 10 3.5C11.8565 3.5 13.637 4.2375 14.9497 5.55025C16.2625 6.86301 17 8.64348 17 10.5Z" stroke="#737373" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                </svg>
                <v-icon @click="onClearSearch" class="search-inner-close" v-if="searchValue">mdi-close-circle-outline</v-icon>
                <input type="search" placeholder="Que recherchez vous ?" v-model="searchValue" ref="searchBar">
            </div>
            <button @click="onCloseSearch" id="closeBtn" class="close"><img src="../assets/croix.svg" ></button>
        </div>

        <div class="results-header textz-34 mx-sm-5 px-10 py-10 " v-if="searchValue">
            <span class="font-weight-regularz">{{ totalProducts }} résultats pour </span>
            <span class="font-weight-boldz">'{{ searchValue }}'</span>          
        </div>

        <div class="products-container mx-sm--5 px-sm-5 d-flex flex-wrap justify-center">
          <Product class="productColumn" 
          v-for="(product) in products" :key="product.reference" :product="product"
          @clicked="toProductDetailsPage(product)"
          />
        </div>
        
        <div class="my-10" v-if="products.length > 0">
          <v-pagination v-model="currentPage" :length="totalPages" total-visible="7" color="red"></v-pagination>
        </div>

        <ChargementWithoutBackdrop v-if="loadingProducts"/>

        <v-dialog
        v-model="detailsDialog"
        
      >
        <DetailsProductComponent
          v-if="selectedProduct"
          :product="selectedProduct"
          @close="setProductDetailsDialogFromSearchBar(false)"
          max-width="960"
        />
      </v-dialog>
    </div>
</template>
  
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DetailsProductComponent from "../components/DetailsProductComponent";
import ChargementWithoutBackdrop from '../components/ChargementWithoutBackdrop.vue'
import _ from "lodash";
import axios from 'axios'
import Product from "./Product.vue";
import {eventBus} from "../main.js";

export default {
    props: {
    },
    components: {
        DetailsProductComponent,
        Product,
        ChargementWithoutBackdrop
    },
    data: () => ({
        selectedProduct: null,
        searchValue: '',
        products: [],
        currentPage: 1,
        totalPages: 1,
        totalProducts: 0,
        loadingProducts: false
    }),
    computed: {
        ...mapGetters([ "imagesBaseURL", "productDetailsDialogFromSearchBar", 'getJwToken', 'apiURL', 'userInfos']),
      detailsDialog: {
        get: function () {
          return this.productDetailsDialogFromSearchBar;
        },
        set: function (bool) {
          this.setProductDetailsDialogFromSearchBar(bool);
        },
      },
    },
    watch: {
      selectedProduct(val) {
        console.log('selected product ->',val);
        if (val != null) {
          this.setProductDetailsDialogFromSearchBar(true);
        }
      },
      productDetailsDialogFromSearchBar(val) {
        if (!val) {
          this.selectedProduct = null;
          this.setProductDetailsDialogFromSearchBar(false);
        }
      },
      searchValue(val) {
        console.log('changed searchValue ->', val)
        this.totalProducts = 0
        this.currentPage = 1
        if(val) {
            this.debouncedGetFiltredProducts()
        } else {
          this.products = []
        }
        const query = { ...this.$route.query }; 
        query.searchTerm = val
        this.$router.replace({ query }).catch(e => { console.log('err pushing route with query update from searchResultsView') });
      },
      currentPage(val) {
        this.getFiltredProducts()
      }
    },
    methods: {
        ...mapActions({ doLogin: "login" }),
        ...mapMutations([
            "setProductDetailsDialog",
            "setProductDetailsDialogFromSearchBar",
            'incrementNbChargement',
            'decrementNbChargement'
        ]),

      getFiltredProducts () {
        if (this.searchValue) {
          this.loadingProducts = true
          let tmp = this.searchValue
          let autoSelect = false
          if(this.searchValue.charAt(this.searchValue.length-1) == '=') {
            autoSelect = true;
            tmp = this.searchValue.split('');
            tmp.pop();
            tmp = tmp.join('');
            this.searchValue = tmp
            console.log('without = ->', tmp);
          }
          axios.get(`${this.apiURL}/api/filteredProductsPaginated/?searchText=${tmp}&page=${this.currentPage-1}`, { params: {}, headers: {"Authorization": 'Bearer ' + this.getJwToken}})
          .then(response => {
            this.products = response.data.content
            console.log(autoSelect, this.products)
            this.totalProducts = response.data.totalElements
            this.totalPages = response.data.totalPages
            if(autoSelect && this.products.length == 1) {
              this.selectedProduct = this.products[0]
            }
          })
          .catch(error => {
            console.log('error fetching filtered prods',error.response)
            
          }).finally(() => {
            this.loadingProducts = false
            this.$refs.searchRoot.scrollIntoView()
          })
        }
      },
      toProductDetailsPage(product) {
        this.selectedProduct = product;
        this.dialogState = true;
      },
      onCloseSearch(e) {
        console.log('onclosesearch', e)
        //searchUtil.onCloseDivSearchResluts()
        const query = { ...this.$route.query };
        delete query.searchTerm
        this.$router.push({ query }).catch(e => { console.log('err pushing route with query update after closing searchResultsView', e) });
      },
      onClearSearch(e) {
        this.searchValue = ''
        if(this.$refs.searchBar) {
          this.$refs.searchBar.focus()
        }
      }
    },

    created() {
      eventBus.$on('onOpenSearchDiv', () => {
        console.log('onOpenSearchDiv, query ->', this.$route.query.searchTerm)
        this.searchValue = this.$route.query.searchTerm || ''
        if(this.$refs.searchBar) {
          this.$refs.searchBar.focus()
        }
        
      })
      this.debouncedGetFiltredProducts = _.debounce(this.getFiltredProducts, 500)
    },
    mounted() {
      
    }
};
</script>
<style scoped>
    .search-header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      width: 100%;
      background-color: #fff;
      z-index: 400;
    }
    .search-input-wrapper {
      margin: auto;
      position: relative;
      width: 80%;
      max-width: 750px;
    }
    .search-inner-magnify {
      position: absolute;
      top: 12px;
      left: 15px;
    }
    .search-inner-close {
      position: absolute;
      top: 10px;
      right: 12px;
      cursor: pointer;
    }

    input {
      display: flex;
      height: 45px;
      padding: 13px 15px 13px 45px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid #E5E5E5;
      background: #FAFAFA;
      width: 100%;
      color: #525252;

      /* text-base/font-normal */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      outline: none;
    }
    input[type="search"]::-webkit-search-cancel-button {
      /* Remove default */
      -webkit-appearance: none;
    }

    .results-header {
      background-colorz: rgb(228, 219, 206);
      text-transform: uppercase;
      font-size: 25px;
    }

    #closeBtn {
      position: absolute;
      top: -5px;
      right: 17px;
      font-size: 36px;
      z-index: 401;
      color: red;
      transformz: translateY(-50%);
    }

    .productColumn {
      transition: all 0.3s;
      /*z-index: -1;*/
      /*width: 300px;
      flex-grow: 0;*/
      width: calc(50% - 10px) ;
      min-width: 0;
      align-self: stretch;
      margin: 5px;
    }

/* TODO: z-index:1000 pour productColumn:hover #REMEMBER_WHY*/


.products-container {
  align-items: stretch;
}
.products-container:nth-child(2n+2) {
  margin-rightz: 0;
}
@media screen and (min-width: 500px) {
  .productColumn {
    width: calc(50% - 10px);
  }
}

@media screen and (min-width: 960px) {
  .productColumn {
    width: calc(33.33% - 10px);
  }
}

@media screen and (min-width: 1264px) {
  .productColumn {
    width: calc(25% - 10px);
  }
}

@media screen and (min-width: 1600px) {
  .productColumn {
    width: calc(20% - 10px);
  }
}
@media screen and (min-width: 1900px) {
  .productColumn {
    width: calc(12.5% - 10px);
  }
}
</style>
  