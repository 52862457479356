<template>
    <div class="prod_info_prix_container">
        <div v-if="product.prixA" class="prod_info_prixa" :class="{ 'line-through': product.enPromo }">
            {{ product.prixA.toFixed(2) }}€
        </div>

        <div v-if="product.enPromo" class="prod_info_prixpromo">
            {{ product.prixPromoFinal.toFixed(2) }}€
        </div>
        <div v-if="userInfos.coefficientRevente != 1 && !product.enPromo" class="prod_info_prixrevente">{{ (product.prixA *
                userInfos.coefficientRevente).toFixed(2) }}€</div>
        <div v-if="userInfos.coefficientRevente != 1 && product.enPromo" class="prod_info_prixrevente">{{ (product.prixPromoFinal *
                userInfos.coefficientRevente).toFixed(2) }}€</div>
    </div>
</template>

<script>
export default {
  props: ['product', 'userInfos']
}
</script>

<style scoped>
.prod_info_prix_container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 8px;
}

.prod_info_prixa {
    color: #171717;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 21px */
}

.prod_info_prixpromo {
    color: #FC1A1E;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 30px */
}

.prod_info_prixrevente {
    color: #1A3EFC;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 21px */
}</style>