import axios from 'axios'
export default {
  state: {
    myOrders: [],
    overlayCommandePassee: false,
  },
  actions: {
    async passerCommande({ rootState, commit }, payload) {
      console.log('validating commande')
      commit('incrementNbChargement')
      let url = `${rootState.apiURL}/api/validateOrder`
      if(payload && payload.commentaire) {
        url += `?commentaire=${payload.commentaire}`
      }
      try {
        await axios.post(url, {}, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
        console.log('validated order')
        commit('commandePassee')
        //alert('Votre commande a été passée avec succès !')
        
      } catch(error) {
        console.log('error validating order ->', error)
        commit('addMessageAxios', {message: error.response.data, isError: true}, { root: true })
      }
      commit('decrementNbChargement')
    },
    async fetchMyOrders({ rootState, state, commit }) {
      console.log('fetching my orders')
      commit('incrementNbChargement')
      //console.log('fetching my orders')
      try {
        let ordersRes =  
          await axios.get(`${rootState.apiURL}/api/myOrders`, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
        state.myOrders = ordersRes.data
      } catch(error) {
        console.log('error fetching my orders ->', error)
      }
      commit('decrementNbChargement')
    },
  },
  mutations: {
    setOverlayCommandePassee (state, bool) {
      state.overlayCommandePassee = bool
    },
    commandePassee(state) {
      state.shoppingCart = []
      //state.shoppingCartChanged = !state.shoppingCartChanged
      //state.majedPanier = !state.majedPanier
      state.overlayCommandePassee = true
    },
  },
  getters: {
    myOrders: state => state.myOrders,
    overlayCommandePassee: state => state.overlayCommandePassee,
  }
}