import axios from 'axios'
export default {
  state: {
    errorMessage: '',
  },
  actions: {
    inscription ({ commit, rootState }, payload) {
      axios.post(`${rootState.apiURL}/api/inscription`, payload) 
        .then(response => {
          commit('inscription', { ...response})
        })
        .catch(error => commit('inscription', {error: error.response.data}))
    }
  },
  mutations: {
    inscription (state, payload) {
      if (payload.error) {
        state.errorMessage = payload.error.message
        console.log('error -> ', payload)
      } 
      else {
        state.errorMessage = 'none'
        console.log('success -> ', payload)
      } 
    }
  },
  getters: {
    getInscriptionErrorMessage (state) { return state.errorMessage }
  }
}