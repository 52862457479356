<template>
    <div class="cursor-pointer product-card" :class="{ enstock: product.enStock, 'prod_in_cart': product.quantity > 0 }" elevation="0">
        <div class="img_infos_container">
            <div class="cursor-pointer imgcontainer" :id="product.reference">
                <!-- class="imgcontainer" -->
                <img v-if="product.nombreImages360 > 0 ||
                    product.nombreImagesAutre > 0
                    " @click.stop="toProductDetailsPage(product)" :src="imageUrl(product)"
                     @error="replaceByDefault"
                     class="prod-image"
                    :class="{ 'border-red': !product.enStock }" />
                <img v-else @click="toProductDetailsPage(product)" src="../assets/unknown2.jpg"
                     @error="replaceByDefault"
                     class="prod-image"
                    :class="{ 'border-red': !product.enStock }" />
                <div class="top-left prod_ref">
                    <span>REF: {{ product.reference }}</span>
                    <div class="red--text contour-text-blanc" v-if="hasOneOfRoles(['ROLE_ADMIN', 'ROLE_MANAGER'])">
                        {{ product.referenceFournisseur }}</div>
                </div>
                <div class="top-right-left prod_drapeau d-flex align-center" v-if="product.origine">
                    <img v-for="(origin, i) in product.origine.split(',')
                    .filter(x => ['IT', 'ES', 'BE', 'TU', 'PO', 'PA', 'EU', 'PO', 'AL', 'UK', 'RT', 'SL','FR', 'EU', 'RE', 'BR', 'LI', 'EN', 'DA' ].includes(x))
                    .map(x => {
                        if(      ['IT', 'ES', 'BE', 'TU', 'PO', 'PA', 'EU', 'PO', 'AL', 'UK', 'RT', 'SL'].includes(x))
                            return 'EU'
                        return x
                    })
                    " 
                    :key="i" :src="imagesBaseURL+'/drapeaux/' + origin + '.png'"
                    class="mr-1"
                     alt="" style="width: 30px;">
                </div>

                <div class="bottom-right overline" style="color: black;">
                    <div style="font-size: 18px;" v-if="hasOneOfRoles(['ROLE_ADMIN'])">
                        {{ Math.round((product.prixA / product.prixAchat - 1) * 100) }}%</div>
                    <div style="font-size: 18px;" v-if="hasOneOfRoles(['ROLE_ADMIN'])">{{
                        Math.round(product.prixAchat * 100) / 100
                    }} €</div>
                    <span class="prod_udv">X{{ product.uniteDeVente }}</span>
                </div>
                <div class="bottom-left overline black--text contour-text-blanc"
                    v-if="hasOneOfRoles(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SHOWROOM'])">
                    <div style="font-size: 18px">{{
                        product.stock
                    }}</div>
                </div>
                <v-btn class="top-right overline" :color="product.enFavoris ? 'red' : 'black'" text="" small="" fab="" @click="toggleFavorite(product)">
                    <v-icon v-if="product.enFavoris">mdi-heart</v-icon>
                    <v-icon v-else>mdi-heart-outline</v-icon>
                </v-btn>

            </div>

            <div class="prod_infos_container">
                <div class="marque_dimensions">
                    <div class="marque">{{ product.marque }} </div>
                    <div class="separator" v-if="product.marque && product.dimensions"></div>
                    <div class="dimensions">{{ product.dimensions }}</div>
                </div>
                <div class="designation">{{ product.designation }}</div>
                <Prices :product="product" :userInfos="userInfos" />
            </div>
        </div>
        <PlusMoinsPanier class="plus_moins_panier_component" :product="product"></PlusMoinsPanier>
    </div>
</template>
   
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import img from "../assets/unknown2.jpg";
import PlusMoinsPanier from "../components/PlusMoinsPanier";
import Prices from "./Prices.vue";
/* eslint-disable no-undef */

export default {
    props: {
        product: { required: true },
    },
    data: () => ({
    }),
    components: {
        // eslint-disable-next-line vue/no-unused-components
        PlusMoinsPanier,
        Prices
    },
    methods: {
        hasOneOfRoles(roles) {
            //console.log('in has one of roles, userInfos ->', this.userInfos)
            return this.userInfos.roles
                .map(x => x.roleName)
                //.split(',')
                .some(role => roles.includes(role))
        },

        toggleFavorite(product) {
            //console.log('fav ->', product.reference)
            if (product.enFavoris) this.deleteFavoris(product);
            else this.addFavoris(product);
            product.enFavoris = !product.enFavoris;
            this.persistToggleFavoris(product);
        },
        toProductDetailsPage(product) {
            this.$emit('clicked', product)
        },
        imageUrl(product) {
            let url = this.imagesBaseURL;
            let index = product.imagePreview;
            if (product.imagePreview.startsWith("autre_")) {
                url += "/autre";
                index = product.imagePreview.split("_")[1];
            }
            return (
                url +
                "/" +
                product.reference +
                "/" +
                index +
                ".jpg?" +
                product.imagesUUID
            ); //performance.now()
        },
        replaceByDefault(e) {
            console.log("on error");
            e.target.src = img;
        },
        ...mapActions([
            "persistToggleFavoris",
        ]),
        ...mapMutations([
            "addFavoris",
            "deleteFavoris",
        ]),


    },
    watch: {
    },
    computed: {
        ...mapGetters([
            "imagesBaseURL",
            "allCategoriesLevels",
            'userInfos',
        ])

    },

    async created() {

    },
    mounted() {
        //console.log("mounted product -> ", this.product);

    },

};
</script>
  
<style scoped>

.prod_ref {
    color: #535353;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 12px */
}

.prod_udv {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 18px */
}

.product-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contentz: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #E6E6E6;
}

.prod_infos_container {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}

.marque_dimensions {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.marque {
    color: #A3A3A3;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
}

.separator {
    width: 1px;
    height: 14px;
    background: #A3A3A3;
}

.dimensions {
    color: #A3A3A3;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 15px */
}

.designation {
    color: #171717;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 19.2px */
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    align-items: start;
    justify-content: start;
    text-align: left;

}


@media screen and (min-width: 1265px) {}

.img_infos_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}
.imgcontainer {
    position: relative;
    text-align: center;
    color: #606060;
    aspect-ratio: 1 / 1;
    /*transition: all 0.5s;
     Animation */
     overflow: hidden;
}
.imgcontainer .prod-image {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-positionz: center center;
    /* Make the background image cover the area of the <div>, and clip the excess */
    background-sizez: cover;
    transformZ: translate(-50%, -50%);
}
.plus_moins_panier_component {
   width: 100%;
}

/* TODO: z-index:1000 pour productColumn:hover #REMEMBER_WHY*/

.prod_drapeau {
    widthz: 32px;
    height: auto;
}

/* Bottom right text */
.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

.top-left {
    position: absolute;
    top: 8px;
    left: 16px;

}

.top-right {
    position: absolute;
    top: 0px;
    right: 0px;
}
.top-right-left {
    position: absolute;
    top: 3px;
    right: 35px;
}

.cursor-pointer {
    cursor: pointer;
}

.contour-text-blanc {
    text-shadow: -1px -1px 0 white, -1px 0px 0 white, -1px 1px 0 white, 0px -1px 0 white, 0px 0px 0 white, 0px 1px 0 white, 1px -1px 0 white, 1px 0px 0 white, 1px 1px 0 white
}


.prods-side-container {}
.prod_in_cart {
    border: 1px solid #22C55E;
}
</style>