import axios from 'axios'
//import utilFunctions from '../../helpers/utilFunctions'
//import router from '../../router'
export default {
  state: {
    categoryID: undefined,
    productsBycategory: [],
    shoppingCart: new Map(), // {ref: '', quantity: ''}
    errorGettingProducts: '',
    imagesBaseURL: 'https://imagesalf.s3.eu-west-3.amazonaws.com',
    parentCategories: [],
    subCategories: [],
    products: [], // {subcatID: '', products: []}
    totalProducts: 0,
    loadedAllProducts: false,
    loadedAllFavoris: false,
    favoris: [],
    favorisChanged: 0,
    pasEnStockSnackBar: false,
    productDetailsDialog: false,
    productDetailsDialogFromSearchBar: false,
    productDetailsDialogFromFavoris: false,
    allCategoriesLevels: [],
    specialCategories: [],
  },
  actions: {
    async fetchAllCategories({commit, rootState, state}) {
      commit('incrementNbChargement')
      try {
        let allCategoriesLevelsRes = (await axios.get(`${rootState.apiURL}/api/allLevelsCategories`, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})).data
        let specialCategoriesRes = (await axios.get(`${rootState.apiURL}/api/specialCategories`, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})).data
        
        if(!rootState.login.userInfos.roles.some(role => role.roleName == 'ROLE_ADMIN' || role.roleName == 'ROLE_MANAGER')) {
          allCategoriesLevelsRes = allCategoriesLevelsRes.filter(x => x.id < 1000)
          
        }
         //TODO gérer path dans pop up car dans nouveautés il y a des trucs qui n'ont pas de catégorie donc dans no cat 
        else {
          allCategoriesLevelsRes.push({
            id: 5000,
            name: 'All',
            childCategories: []

          })
        }
        
        allCategoriesLevelsRes.unshift(
          {
            id: 7000,
            name: 'Nouveautés',
            childCategories: [],
            background: 'linear-gradient(225deg, rgba(255, 214, 79, 0.60) 0%, #FFD64F 100%)'
          }
        )
        allCategoriesLevelsRes.unshift(
          {
            id: 6000,
            name: 'Moments',
            childCategories: [],
            background: 'linear-gradient(43deg, #357AFF 0%, rgba(53, 122, 255, 0.50) 100%)'
          }
        )
        allCategoriesLevelsRes.unshift(
          {
            id: 8000,
            name: 'Soldes',
            childCategories: [],
            background: 'linear-gradient(58deg, #FC1A1E 2.96%, rgba(252, 26, 30, 0.50) 99.81%)'
          }
        )
        //todo recup  nb prods hiver et promo, peut-être bouger les unshift côté back
        commit('setAllLevelsCategories', {all: allCategoriesLevelsRes, special: specialCategoriesRes})
        console.log(state.allCategoriesLevels)
      } catch(err) {
        console.log(err)
      }
      commit('decrementNbChargement')
    },
    fetchProducts({rootState, commit}, query) {
      let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
      let sorting = ''
      if(query.categoryID == 8000) {
        sorting += 'sort=prixPromoMajDate,desc&'
      } else if(query.categoryID == 7000) {
        sorting += 'sort=creationDate,desc&'
      }
      sorting += 'sort=category.id,asc&sort=designation,asc'
      //console.log('queryString fetching prods ->', queryString)
      commit('incrementNbChargement')
      return axios.get(`${rootState.apiURL}/api/products?${queryString}&${sorting}`, //&enStock=true
       { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
       .then(result => { 
        commit('decrementNbChargement')
        return result; })
        .catch(error => { 
          commit('decrementNbChargement')
          return Promise.reject(error);
        })
    },
    async loadRemoteFavoris ({ state, rootState, commit }) {
      commit('incrementNbChargement')
      //console.log('loading remotely favoris')
      state.favoris = new Set()
      try {
        let favorisResponse = await axios.get(`${rootState.apiURL}/api/favoris`, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
        state.favoris = favorisResponse.data.map(favItem => ({...favItem.product, enFavoris: true}))
        state.loadedAllFavoris = true
        //commit('updateProductsWithCartItems', state.favoris)
        console.log('loaded favoris -> ', state.favoris)
      } catch(err) {
        console.log('error loading remotely favoris -> ', err)
      }
      commit('decrementNbChargement')
    },
    persistToggleFavoris ({ rootState }, product) {

      axios.post(`${rootState.apiURL}/api/favoris?productReference=${product.reference}`, {},  { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
      console.log('toggled favoris to server')
    },
    
    async loadMarques({ rootState }, query) {
      let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
      console.log('queryString ->', queryString)
      return axios.get(`${rootState.apiURL}/api/marques?${queryString}`,
       { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
    },
    async loadMatieres({ rootState }, query) {
      let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
      console.log('queryString ->', queryString)
      return axios.get(`${rootState.apiURL}/api/matieres?${queryString}`,
       { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
    },
    async loadFournisseurs({ rootState }, query) {
      let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
      console.log('queryString ->', queryString)
      return axios.get(`${rootState.apiURL}/api/fournisseurs?${queryString}`,
       { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
    },
    
    
  },
  mutations: {
    setAllLevelsCategories(state, payload) {
      //payload = payload.sort((x,y) => x.id - y.id)
      state.allCategoriesLevels = payload.all
      state.specialCategories = payload.special
    },
    setProductDetailsDialog (state, bool) {
      state.productDetailsDialog = bool
    },
    // eslint-disable-next-line no-unused-vars
    setProductDetailsDialogFromSearchBar (state, bool) {
      state.productDetailsDialogFromSearchBar = bool
    },
    setProductDetailsDialogFromFavoris (state, bool) {
      state.productDetailsDialogFromFavoris = bool
    },
    updateProductsWithFavoris(state, products) {
      console.log(products)
      products.forEach(prod => {
        let productInFavoris = state.favoris.find(x => x.reference == prod.reference)
        //console.log('reference ->', prod.reference, ' productInCart ->', productInCart)
        if(productInFavoris) prod.enFavoris = true
        else prod.enFavoris = false
      })
    },
    toggleProductFavoris(state, product) {
      let originalProduct = state.products.find(prod => prod.reference == product.reference)
      if(originalProduct) {
        originalProduct.enFavoris = !originalProduct.enFavoris
      }
    },
    addFavoris (state, product){
      state.favoris.push(product)
      //state.favorisChanged = state.favoris.size
      //console.log(state.favoris)
    },
    deleteFavoris (state, product){
      //console.log('deleting prod from favoris, ref ->', product.reference, ' , favoris -> ', state.favoris)
      state.favoris = state.favoris.filter(prod => prod.reference != product.reference)
      //state.favorisChanged = state.favoris.size
      //console.log(state.favoris)
    },    
    
    setPasEnStockSnackBar(state, bool){
      state.pasEnStockSnackBar = bool
    }
  },
  getters: {
    imagesBaseURL : state => state.imagesBaseURL,
        
    favoris: state => state.favoris,
    favorisProducts: state => state.favoris,
    favorisChanged: state => state.favorisChanged,
    pasEnStockSnackBarGeneral: state => state.pasEnStockSnackBar,
    productDetailsDialog: state => state.productDetailsDialog,
    productDetailsDialogFromSearchBar: state => state.productDetailsDialogFromSearchBar,
    productDetailsDialogFromFavoris: state => state.productDetailsDialogFromFavoris,
    allCategoriesLevels: state => state.allCategoriesLevels,
    specialCategories: state => state.specialCategories
  }
}