<template>
  <div
    class="compte_panier"
    :class="{
      c_p_pcz: $vuetify.breakpoint.xl,
      c_p_tablettez: $vuetify.breakpoint.lgAndDown,
    }"
  >
    <div class="favoris pt-1" @click.stop="toFavorisPage" v-if="false || true">
      <img
        class="cursor-pointer"
        src="../assets/heart.svg"
        style="width: 32px"
        alt=""
        
      />
    </div>
    <div class="utilisateur pl-6 pr-1  pbz-3" @click.stop="toProfilPage">
      <img
        class="cursor-pointer"
        src="../assets/user.svg"
        style="width: 32px"
        alt=""
      />
      <div class="pop-up-utilisateur">
        <div class="header-hover-top px-5 pt-5 pb-2">
          <div class="subtitle-2 mb-3 uppecase">{{userInfos.society}}</div>
          <div style="border-bottom: 1px solid grey"></div>
          <div style="text-align: left" class="pl-2 pt-4">
            <div class="user-item" @click.stop.self="toHome">Accueil</div>
            <div class="user-item" @click.stop.self="toProfilPage">Mon profil</div>
            <div class="user-item" @click.stop.self="toAccountPage">Mes commandes</div>
          </div>
        </div>
        <div class="header-hover-bottom py-4">
          <v-btn
            v-if="!( userInfos.roles.map(x => x.roleName).includes('ROLE_MANAGER', 'ROLE_SHOWROOM') )"
            text
            shaped
            style="
              width: 80%;
              background-color: rgb(253, 26, 22);
              color: white;
              border-radius: 30px;
            "
            class="mx-auto py-2"
            @click.stop="logout"
          >
            JE ME DECONNECTE
          </v-btn>
        </div>
      </div>
    </div>
    <div class="panier pl-3 pbz-3">
      <div
        class="cursor-pointer"
        @click="toShoppingCartPage"
        :key="majedPanier"
      >
      <v-badge
          :content="shoppingCart.length"
          :value="shoppingCart.length"
          class=""
           color="red"
          >
          
          <img
            src="../assets/shopping-cart.svg"
            style="width: 32px;"
            alt=""
          />
        </v-badge>
      
      </div>
      <div class="pop-up-panier">
        <div class="panier-articles pa-5">
          <div
            v-for="(cartItem) in shoppingCart.filter(item => item.product.enStock)"
            class="d-flex justify-space-between"
            :key="cartItem.product.reference"
            
            v-show="cartItem.product.enStock"
          >
          <!-- :set="(product = productByRef({ reference: product[0] }))" -->
            <div class="panier-image-infos">
              <div style="width: 70px; height: 70px">
                <img
                  :src="imageUrl(cartItem.product)"
                  style="width: 100%; height: 100%"
                  @error="replaceByDefault"
                />
              </div>
            </div>
            <div style="width: 138px" class="pr-3 pbz-3">
              <p>{{ cartItem.product.designation }}</p>
            </div>
            <div style="width: 55px">
              <p>{{ cartItem.quantity }}x</p>
            </div>
            <div style="width: 98px" v-if="userInfos.roles.some(x => x.level >= 10)">
              <p v-if="!cartItem.product.enPromo">
                {{
                  (
                    cartItem.product.prixA * cartItem.quantity
                  ).toFixed(2)
                }}
                €
              </p>
              <p v-else>
                {{
                  (
                    cartItem.product.prixPromoFinal * cartItem.quantity
                  ).toFixed(2)
                }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="panier-footer px-5"
          style="background-color: rgb(245, 245, 245)"
        >
          <div class="header-cart-totals d-flex align-end" v-if="userInfos.roles.some(x => x.level >= 10)">
            <div class="text-h5 mr-2">TOTAL</div>
            <div class="pb-1 mr-auto">( {{ shoppingCart.length }} articles)</div>
            <div class="text-h5" style="color: rgb(253, 26, 22)">
              <span>
                {{ 
                  (
                  shoppingCart.filter(item => item.product.enStock && !item.product.enPromo)
                 .reduce((a,b) =>a + b.quantity*b.product.prixA, 0)
                 +
                 shoppingCart.filter(item => item.product.enStock && item.product.enPromo)
                 .reduce((a,b) =>a + b.quantity*b.product.prixPromoFinal, 0)
                ).toFixed(2)  
                }}
              </span>
               €
            </div>
          </div>
          <div class="header-hover-bottom py-4">
            <v-btn
              text
              shaped
              style="
                width: 100%;
                background-color: rgb(253, 26, 22);
                color: white;
                border-radius: 30px;
              "
              class="mx-auto py-2"
              @click.stop="toShoppingCartPage"
            >
              TERMINER MA COMMANDE
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import img from "../assets/unknown2.jpg";
export default {
  methods: {
    toHome() {
      this.$router.push({path: '/'})
    },
    toAccountPage () {
      this.$router.push({path: '/account'})
    },
    toShoppingCartPage() {
      this.$router.push({path: '/panier'})
    },
    toFavorisPage() {
      this.$router.push({path: '/favoris'})
    },
    toLoginPage() {
      this.$router.push({path: '/login'})
    },
    toProfilPage() {
      this.$router.push({path: '/profil'})
    },
    logout() {
      this.doLogout() 
      this.toLoginPage()
    },
    ...mapActions(['doLogout']),
    imageUrl(product) {
      //console.log('imageUrl -> ', product)
      let url = this.imagesBaseURL;
      let index = product.imagePreview;
      if (product.imagePreview.startsWith("autre_")) {
        url += "/autre";
        index = product.imagePreview.split("_")[1];
      }
      return (
        url +
        "/" +
        product.reference +
        "/" +
        index +
        ".jpg?" +
        product.imagesUUID
      ); //performance.now()
    },
    replaceByDefault(e) {
      console.log("on error");
      e.target.src = img;
    },
  },
  computed: {
    ...mapGetters([
      "majedPanier",
      "shoppingCart",
      "do_animate_panier",
      "productByRef",
      'imagesBaseURL',
      'userInfos'
    ]),
  },
};
</script>

<style scoped>
.compte_panier {
  position: relative;
  margin-leftz: auto;
  margin-topz: 15px;
  display: flex;
  width: 128px;
  justify-content: space-between;
  align-items: center;
  /*background-color: cadetblue;*/
}
.c_p_pc {
  right: 100px;
}
.c_p_tablette {
  right: 50px;
}

.header-hover-bottom {
  background-color: rgb(245, 245, 245);
}
.user-item {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.user-item:hover {
  color: rgb(253, 26, 22);
}
.favoris {
  margin-topz: -22px;
}
.utilisateur {
  margin-topz: -6px;
  position: relative;
}
.compte_panier {
  margin-right: 10px;
  /*background-color: cadetblue;*/
  align-self: center;
  z-index: 30;
}
/* compte et panier  */
.pop-up-utilisateur {
  width: 290px;
  /*height: 315px;*/
  position: absolute;
  margin-top: -1px;
  top: calc(100% + 5px);
  right: 0%;
  background-color: rgb(254, 254, 254);
  border-top: 1px solid rgb(253, 26, 22);
  box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.05);
  z-index: 300;
  display: none;
  box-shadow: rgba(35, 31, 32, 0.1) 0px 0px 10px 1px;
}
.pop-up-panier {
  width: 450px;
  /*height: 315px;*/
  position: absolute;
  margin-topz: -4px;
  margin-bottomz: -3px;
  top: calc(100% + 2px);
  right: 0;
  background-color: rgb(254, 254, 254);
  border-top: 1px solid rgb(253, 26, 22);
  box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.05);
  z-index: 300;
  display: none;
  box-shadow: rgba(35, 31, 32, 0.1) 0px 0px 10px 1px;
}
.panier-articles {
  max-height: 350px;
  overflow-y: scroll;
  scrollbar-color: rgb(0,0,0, 0.2) transparent;
  scrollbar-width: thin;
}
.utilisateur::after {
  content: "";
  position: absolute;
  top: 22px;
  left: 33px;
  border-left: 1px solid rgb(253, 26, 22);
  border-top: 1px solid rgb(253, 26, 22);
  /*border-right: 1px solid rgb(253, 26, 22);
    border-bottom: 1px solid rgb(253, 26, 22);*/
  width: 14px;
  height: 14px;
  margin-leftz: -31px;
  margin-top: 14px;
  transform: rotate(45deg);
  background-color: rgb(254, 254, 254);
  opacity: 0;
  z-index: 301;

  border-radius: 0;
}
.panier::after {
  content: "";
  position: absolute;
  top: 64%;
  right: 2px;
  border-left: 1px solid rgb(253, 26, 22);
  border-top: 1px solid rgb(253, 26, 22);
  /*border-right: 1px solid rgb(253, 26, 22);
    border-bottom: 1px solid rgb(253, 26, 22);*/
  width: 14px;
  height: 14px;
  margin-left: 19px;
  margin-top: 11px;
  transform: rotate(45deg);
  background-color: rgb(254, 254, 254);
  opacity: 0;
  z-index: 301;

  border-radius: 0;
}
.uppecase {
  text-transform: uppercase;
}
@media screen and (min-width: 1265px) {
  .utilisateur:hover::after {
    opacity: 1;
  }
  .panier:hover {
    /*transform: scale(1.02);*/
  }
  .panier:hover::after {
    opacity: 1;
  }
  .utilisateur:hover .pop-up-utilisateur {
    display: block;
  }
  .panier:hover .pop-up-panier {
    display: block;
  }
}


.panier.animate-panier {
  /*transition: transform 0.5s linear;*/
  animation: panier-anim 0.7s linear;
}
@keyframes panier-anim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.7);
  }
  100% {
    transform: scale(1);
  }
}
.panier .v-badge__badge {
  background-colorZ: blue !important;
}
</style>