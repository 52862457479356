import {eventBus} from "../main.js";
let obj =   {
    onOpenDivSearchResluts:  () => {
        console.log('in onOpenDivSearchResluts')
        let sidenav = document.getElementById("mySidenav");
        console.dir('sidenav ->', sidenav)
        if(sidenav) {
            console.log('in if')
            sidenav.classList.add("active");
            document.getElementsByTagName('html')[0].style.overflow = 'hidden'
            eventBus.$emit('onOpenSearchDiv')
        }
    },
    onCloseDivSearchResluts:  () => {
        console.log('in onCloseDivSearchResluts')
        let sidenav = document.getElementById("mySidenav");
        if(sidenav) {
            sidenav.classList.remove("active")
            document.getElementsByTagName('html')[0].style.overflow = ''
        }
    },
}

export default obj