<template>
    <div>
      <div class="lds-dual-ring"></div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: fixed;
    left: 50%;
    margin-left: -32px;
    top: 50%;
    z-index: 401;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 6px solid rgb(240, 101, 101);
    border-color: rgb(240, 101, 101) transparent rgb(240, 101, 101) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>