import axios from 'axios';
//import router from '../router'

import store from '../store'
import router from '../router';

export default function setup() {
    axios.interceptors.request.use(function(config) {

        return config;
    }, function(err) {
        console.log('requête pas partie !', err)
        //alert('interceptor reject direct')
        /**/store.commit('decrementNbChargement')
        return Promise.reject(err);
    });
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        //console.log('in interceptor response header -> ', response.headers)
        if(response.headers.newjwt) {
            store.commit('updateJWT', {jwToken: response.headers.newjwt})
        }
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        //console.log('in interceptor error -> ', error.response)
        if( error.response.status == 403) { // si le serveur refuse l'accès, token potentiellement expiré
            console.log('is 403')
            //localStorage.clear()
            console.log(error.response)
            if(error.response.headers.compteinactif) {
                store.commit('updateCompteInactif', true)
                if(router.currentRoute.name != 'Home') {
                    console.log('router replacing to inactif')
                    router.replace({name: 'Home'})
                } 
                console.log('inactif')

                //
            } else {
                localStorage.removeItem('connected')
                localStorage.removeItem('adminConnected')
                localStorage.removeItem('jwt')
                if(router.currentRoute.name != 'Login') {
                    document.location.replace('/login')
                    //router.replace({name: 'Login'})
                }
                console.log('déco')
                // 
            }
            
            //router.replace({name: 'Login'})
            //return Promise.resolve({data: {}});
        }
        return Promise.reject(error);
      });
}