import axios from 'axios'
import router from '../../router'
//import { setJwt, setAdminConnected, setConnected }from '../../data/userData.js'
export default {
  state: {
    errorMessage: '',
    connected: localStorage.connected == 'true' ? true : false,
    compteInactif: false,
    adminConnected: localStorage.adminConnected == 'true' ? true : false,
    jwToken: localStorage.jwt,
    userInfos: undefined // {privileges: []}
  },
  actions: {
    login ({ commit, rootState }, payload) {
      axios.post(`${rootState.apiURL}/authenticate`, payload)
        .then(response => {
          
          commit('login', { ...response})
          rootState.products.dispatch('fetchCategoriesAndProducts')
        })
        .catch(error => commit('login', {error: error.response.data, status: error.response.status}))
    },
    async fetchMyInfos ({ commit, rootState }) {
      try {
        let infosRes = await axios.get(`${rootState.apiURL}/api/myinfos`, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
        commit('setMyInfos', infosRes.data)
      } catch(err) {
        console.log('error fetching user infos ->', err)
      }     
      
    },
    doLogout ({ commit }) {
      //localStorage.clear()
      localStorage.removeItem('connected')
      localStorage.removeItem('adminConnected')
      localStorage.removeItem('jwt')
      commit('doLogout')
    }
  },
  mutations: {
    updateCompteInactif(state, payload) {
      state.compteInactif = payload
    },
    updateJWT(state, payload) {
      state.jwToken = payload.jwToken
      localStorage.setItem('jwt', payload.jwToken)
    },
    // eslint-disable-next-line no-unused-vars
    loginSuccess(state, payload) {
      
    },
    login (state, payload) {
      console.log('in login commit, payload -> ', payload)
      if (payload.error ||  payload.status != 200) {
        state.errorMessage = payload.error.message
        state.jwToken = ''
        console.log('error -> ', payload)
      } 
      else {
        console.log(payload.data)
        state.connected = true
        //setConnected(true)
        localStorage.setItem('connected', true);
        state.adminConnected = payload.data.admin
        //setAdminConnected(payload.data.admin)
        localStorage.setItem('adminConnected', payload.data.admin);
        console.log('setting adminConnected', payload.data.admin)
        state.jwToken = payload.data.jwt
        localStorage.setItem('jwt', payload.data.jwt);
        //state.errorMessage = ''
        router.go()
      } 
    },
    doLogout (state) {
      state.connected = false
      state.adminConnected = false
      state.jwToken = undefined
    },
    setMyInfos(state, payload) {
      state.userInfos = payload
    }
  },
  getters: {
    getErrorMessage (state) { return state.errorMessage },
    getJwToken (state) { return state.jwToken },
    getConnected (state) { return state.connected },
    userInfos: state => state.userInfos,
    compteInactif: state => state.compteInactif
  }
}