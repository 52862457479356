<template>
  <div id="navigationz" class="mx-auto">
    <ul>
      <li>Copyright © 2021 ALEAF</li>
      <li v-for="(footer, i) of footers" :key="i">
        <router-link
          :to="footer.path"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a style="color: white !important" :href="href" @click="navigate">{{footer.name}}</a>
          </li>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    footers: [{path: '/confidentialite', name: 'Confidentialité'},
     {path: '/mentions', name: 'Mentions légales'}, {path: '/conditions', name: 'Conditions générales de vente'}, {path: '/faq', name: 'FAQ'}, ]
  })
};
</script>

<style scoped>
a {
  color: blue !important;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
#navigationz ul {
  margin: 0 auto;
  padding: 15px;
  color: white;
  text-align: center;
}

#navigationz ul li {
  list-style-type: none;
  display: inline;
}

#navigationz li:not(:first-child):before {
  content: " | ";
}
</style>