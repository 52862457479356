<template>
	<div class="" style="overflow: hidden !important;">
		<div class="search-wrapper">
				<!-- icon loupe à gauche -->
			<v-icon v-if="false" class="search-inner-magnify">mdi-magnify</v-icon>
			<svg class="search-inner-magnify" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="search">
				<path id="Vector" opacity="0.9" d="M21 21.5L15 15.5M17 10.5C17 11.4193 16.8189 12.3295 16.4672 13.1788C16.1154 14.0281 15.5998 14.7997 14.9497 15.4497C14.2997 16.0998 13.5281 16.6154 12.6788 16.9672C11.8295 17.3189 10.9193 17.5 10 17.5C9.08075 17.5 8.1705 17.3189 7.32122 16.9672C6.47194 16.6154 5.70026 16.0998 5.05025 15.4497C4.40024 14.7997 3.88463 14.0281 3.53284 13.1788C3.18106 12.3295 3 11.4193 3 10.5C3 8.64348 3.7375 6.86301 5.05025 5.55025C6.36301 4.2375 8.14348 3.5 10 3.5C11.8565 3.5 13.637 4.2375 14.9497 5.55025C16.2625 6.86301 17 8.64348 17 10.5Z" stroke="#737373" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
			</svg>
			<input @focus="onFocus" type="search" id="search" placeholder="Que recherchez vous ?" data-search>
		</div>

		<div id="mySidenav" class="sidenav u-scroll-bar-hide" ref="sidenav">
			
			<SearchResultsView></SearchResultsView>
		</div>

	</div>
</template>
<script>
/* eslint-disable no-undef */

import { mapGetters, mapMutations } from "vuex";
import SearchResultsView from "./SearchResultsView.vue";
import _ from "lodash";
import searchUtil from "../helpers/searchUtil.js";
export default {
	data: () => ({
		labelRecherche: "",
		search: null,
		dialogState: false,
		isLoading: true,
		items: []
	}),
	components: {
		SearchResultsView
	},
	watch: {

	},
	methods: {
		hasOneOfRoles(roles) {
			//console.log('in has one of roles, userInfos ->', this.userInfos)
			return this.userInfos.roles
				.map(x => x.roleName)
				//.split(',')
				.some(role => roles.includes(role))
		},

		

		// eslint-disable-next-line no-unused-vars
		onFocus(e) {
			console.log('on focus', e)
			e.target.blur()
			//searchUtil.onOpenDivSearchResluts()
			const query = { ...this.$route.query };
			query.searchTerm = ''
			this.$router.push({ query }).catch(e => { console.log('err pushing route with query update to open searchResultsView', e) });
		},
		// eslint-disable-next-line no-unused-vars
		onBlur(e) {
			console.log('on blur', e)
		},

	},
	computed: {

	},
	created() {
	},
	mounted() {
		//let autocompleteInput = this.$refs.autocomplete.$refs.input
		//autocompleteInput.addEventListener('focus', this.onFocus, true)
		//autocompleteInput.addEventListener('blur', this.onBlur, true)    
		if(this.$route.query.searchTerm != undefined) {
			searchUtil.onOpenDivSearchResluts()
		}
	},

};
</script>
  
<style scoped>
input#search {
	display: flex;
	height: 45px;
	padding: 13px 15px 13px 45px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 16px;
	border: 1px solid #E5E5E5;
	background: #FAFAFA;
	width: 100%;
	cursor: pointer;
	color: #525252;

	/* text-base/font-normal */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
}

.search-wrapper {
	background-colorz: antiquewhite;
	position: relative;
}
.search-inner-magnify {
	position: absolute;
	top: 12px;
	left: 15px;
}

.search-wrapper  {
	width: 100%;
	margin: auto;
}

.sidenav {
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 400;
	top: 0;
	right: -100%;
	background-color: #fff;
	padding-topz: 30px;
	transition: right 0.5s ease;
	overflow: auto;
}

/* Active class */
.sidenav.active {
	right: 0;
}

/* Close btn */

</style>