import axios from 'axios'
export default {
  state: {
    shoppingCart: [], // new Map(), // {ref: '', quantity: ''}
    majedPanier: false,
    shoppingCartChanged: false,
    loadedShoppingCart: false,
  },
  actions: {
    updateCartItemCustomPrice({rootState}, payload) {
      
      return axios.put(`${rootState.apiURL}/api/cartItem/${payload.cartItemID}/${payload.newCustomPrice}`, {}, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
    },
    async loadRemoteShoppingCart ({ state, rootState, commit }) { 
      commit('incrementNbChargement')
      //console.log('loading remotely shopping cart')

      //state.shoppingCart = new Map()
      try {
        let shoppingCartResponse = await axios.get(`${rootState.apiURL}/api/cart`, { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
        //shoppingCartResponse = shoppingCartResponse.data.map(item => [item.productReference, {quantity: item.quantity, subCatId: item.subCatId}])
        state.shoppingCart = shoppingCartResponse.data
        state.loadedShoppingCart = true
        /*shoppingCartResponse.data.forEach(item => {
          
          state.shoppingCart.set(item.productReference, {quantity: item.quantity, reference: item.productReference, stock: item.stock})
          //console.log('item -> ', state.shoppingCart.get(item.product.reference))
        })*/
        //commit('updateProductsWithCartItems', rootState.products.products)
        
        //for()
        //console.log('cart res après mapping ->', shoppingCartResponse);
        //console.log('shopping cart après load remote -> ', state.shoppingCart)
        //state.shoppingCart = new Map(shoppingCartResponse.data)
      } catch(error) {
        console.log('error loading remotely shopping cart -> ', error)
      }
      commit('decrementNbChargement')
      //console.log('after loaded remotely shopping cart')
    },
    persistPanier ({ rootState }, productQuantity) {
      //axios.post(`${rootState.apiURL}/api/saveCart`, Array.from(state.shoppingCart.entries()).map(x => ({productReference: x[0], quantity: x[1].quantity}) ),  { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}})
      //console.log('posted to server')
      return axios.post(`${rootState.apiURL}/api/cartItem`, productQuantity,  { params: {}, headers: {"Authorization": 'Bearer ' + rootState.login.jwToken}} )
    },
    
  },
  mutations: {
    updateCartQuantity(state, cartItem) {
      console.log('updating cart, cartItem -> ', cartItem, state.shoppingCart)
      /*console.log('shoppingCart ->', state.shoppingCart)
      console.log(state.shoppingCart.find(x => x.id == cartItem.id))*/
      let cartItemToUpdate = state.shoppingCart.find(x => x.id == cartItem.id)
      if(cartItemToUpdate) { //produit présent dans le panier, mettre  à jour la quantité
        if(cartItem.quantity == 0) { //si valeur atteint 0, supprimer du panier
         // console.log('filtered shoppingCart ->', state.shoppingCart.filter(x => x.id != cartItem.id))
          state.shoppingCart = state.shoppingCart.filter(x => x.id != cartItem.id)
        } else {
          cartItemToUpdate.quantity = cartItem.quantity
        }
        //cartItemToUpdate = cartItem
        //console.log(state.shoppingCart.find(x => x.id == cartItem.id))
      } else { //ajout nouveau produit dans le panier
        state.shoppingCart.unshift(cartItem) //TODO une solution pour garder l'ordre après refresh -> colonne updated_at pour pouvoir trier avec côté back, unshift pour front
      }
      
      
      /*
      state.shoppingCart.set(product.reference, {quantity: product.quantity, reference: product.reference, stock: product.stock})
      if(product.quantity == 0) {
        state.shoppingCart.delete(product.reference)
        state.shoppingCartChanged = !state.shoppingCartChanged
      }
      state.majedPanier = !state.majedPanier*/
    },
    updateProductsWithCartItems(state, products) {
      //console.log('updating products with cartitems, prods ->', products)
      //console.log('updating products with cartitems, shoppingCart ->', state.shoppingCart)
      products.forEach(prod => {
        let productInCart = state.shoppingCart.find(cartItem => cartItem.product.reference == prod.reference)
        //console.log('reference ->', prod.reference, ' productInCart ->', productInCart)
        if(productInCart) prod.quantity = productInCart.quantity
        else prod.quantity = 0
        prod.quantityTmp = prod.quantity
        //prod.enFavoris = state.favoris.has(prod.reference)
        //console.log(prod)
      })
      state.majedPanier = !state.majedPanier
    },
  },
  getters: {
    shoppingCart: state => state.shoppingCart,
    shoppingCartChanged: state => state.shoppingCartChanged,
    // eslint-disable-next-line no-unused-vars
    majedPanier: state => state.majedPanier,
    loadedShoppingCart: state => state.loadedShoppingCart
  }
}