<template>
  <v-app style="background-colorz: #f3f3f3">
    <!--<CategoriesDrawer v-if="getConnected"></CategoriesDrawer>-->
    <v-main class="pt-0 ">
      <Chargement v-if="nbChargement > 0" />
      <my-header
        v-if="getConnected && !compteInactif && userInfos && ($vuetify.breakpoint.lgAndUp || true)"
      ></my-header>
      <!--<div class="to-panier" id="to-panier">1</div>-->
      <router-view v-if="true || nbChargement <= 0 || !getConnected" :key="$route.path" />
      <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">
        Une nouvelle version est disponible
        <v-btn text @click="refreshApp">
          Mettre à jour
        </v-btn>
      </v-snackbar>
      <v-snackbar v-model="showMessagesAxiosSnack" :timeout="messagesSnackTimeout" class="hide_on_print">
          <ul>
            <li v-for="(message,i) of messagesAxios" :key="i">
              <p class="caption text-uppercase font-weight-bold" :class="{'red--text': message.isError, 'green--text': !message.isError}">{{message.message}}</p>
            </li>
          </ul>
          
      </v-snackbar>
    </v-main>
    <v-footer
      app
      v-if="$route.name != 'Login'"
      class="black"
      style="position: static"
    >
      <Footer></Footer>
    </v-footer>
    <a href="https://wa.me/33768671682" class="whatsapp_float" target="_blank"> <i class="fa  fa-whatsapp whatsapp-icon"></i></a>
  </v-app>
  
</template>
<script>
// @ is an alias to /src
import { mapGetters, mapMutations, mapActions } from "vuex";
import MyHeader from "./components/MyHeader";
import Chargement from "./components/Chargement";
import Footer from './components/Footer'
import update from './mixins/update'
import CategoriesLateral from "./components/CategoriesLateral"
// import CategoriesDrawer from '@/components/CategoriesDrawer.vue'
// eslint-disable-next-line no-unused-vars
// var
export default {
  name: "Home",
  mixins: [update],
  components: {
    //CategoriesDrawer,
    MyHeader,
    Chargement,
    Footer,
    CategoriesLateral
  },
  data: () => ({
    showMessagesAxiosSnack: false,
    messagesSnackTimeout: -1
  }),
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "220px";
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "600px";
        case "xl":
          return "800px";
      }
      return "";
    },
    ...mapGetters([
      "getConnected",
      'compteInactif',
      "getJwToken",
      "shoppingCart",
      "allCategoriesLevels",
      "loadedAllProducts",
      "nbChargement",
      'userInfos',
      'audio',
      'messagesAxios'
    ]),
  },
  methods: {
    toLoginPage() {
      this.$router.replace({ path: "/login" });
    },
    logout() {
      this.doLogout();
      this.toLoginPage();
    },
    ...mapMutations(["initialize", "toggleDrawer", 'setShowMessagesAxiosSnack', 'addMessageAxios']),
    ...mapActions([
      "doLogout",
      "fetchCategoriesAndProducts",
      "loadRemoteFavoris",
      "loadRemoteShoppingCart",
      "fetchAllCategories",
      "fetchMyInfos",
      'fetchMyOrders'
    ]),
  },
  watch: {
    messagesAxios(maj) {
      console.log('messages axios changed ->', maj)
      
      if(maj.length > 0) {
        this.showMessagesAxiosSnack = true
      } else {
        this.showMessagesAxiosSnack = false
      }
      if(maj.length == 1) {   
        this.messagesSnackTimeout = 5000
      } else if(maj.length > 1) {
        this.messagesSnackTimeout = -1
      }
    }
  },
  async created() {
    //alert(this.imageHeight)
    console.log(this.$vuetify.breakpoint);
    console.log('volume ->', this.audio.volume)
    this.audio.volume = 0.1
    //alert("Your screen resolution is: " + screen.width + "x" + screen.height);
    // eslint-disable-next-line no-constant-condition
    if (true) {
      if (!this.getConnected) {
        console.log('in app, not connected, redirecting to login')
        this.$router.replace({ name: "Login" });
      } 
      else if(this.compteInactif) {
        console.log('in app, compte inactif')
      }
      else {
        
        //this.loadLocalShoppingCart()
        //this.loadRemoteShoppingCart()
        console.log('in app, else')
        await this.fetchMyInfos();
        console.log('in app created, myinfos fetched ? -> ', this.userInfos)
        await this.fetchMyOrders()
        await this.fetchAllCategories();
        //

        //
        
        await this.loadRemoteShoppingCart();
        //await this.loadRemoteFavoris();
        //await this.fetchCategoriesAndProducts();

      }
    }
  },
  mounted() {},
};
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.to-panier {
  position: absolute;
  z-index: 10;
  top: 0;
  /*opacity: 0.7;*/
  left: 0%;
  width: 35px;
  height: 35px;
  padding-top: 0.4em;
  background-color: cornflowerblue;
  border-radius: 2em;
  color: white;
  /*transform: translateY(-50px);*/
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.letter-spacing {
  letter-spacing: 0.05em;
}
.margin-top-pc {
  margin-topz: 190px !important;
  margin-top: 50px !important;
}
.myred--text {
  color: rgb(253, 26, 22);
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a {
  color: rgb(253, 26, 22) !important;
}
.theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
.v-breadcrumbs__item {
  color: #000 !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important; /* 14.4px */
  letter-spacing: -0.36px !important;
}
@media screen and (max-width: 1265px) {
  .v-breadcrumbs__item {
    font-size: 10px;
  }
}
.line-through {
  text-decoration: line-through;
}
/* for desktop */
.whatsapp_float {
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:30px;
	background-color:#25d366;
	color:#FFF !important;
	border-radius:50px;
	text-align:center;
        font-size:30px;
	box-shadow: 2px 2px 3px #999;
        z-index:100;
}

.whatsapp-icon {
	margin-top:16px;

}
.whatsapp-icon:hover {
  transform: scale(1.3);
}
/* for mobile */
@media screen and (max-width: 767px){
     .whatsapp-icon {
      margin-top:10px;
     }
    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}
.cursor-pointer {
  cursor: pointer;
}
.border-red {
  border: solid red 2px;
}
.v-snack__wrapper {
  background-colorz: rgb(172, 172, 175) !important;
}
.v-overlay.v-overlay--active .v-overlay__scrim {
  opacity: 0.8 !important;
}
.u-scroll-bar-hide {
    overflow: -moz-scrollbars-none !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.u-scroll-bar-hide::-webkit-scrollbar {
    width: 0 !important;
    background: transparent !important;
}
.text_align_center {
  text-align: center;
}
.text_align_left {
  text-align: left;
}
.v-app-bar.v-app-bar--fixed {
    z-indexZ: 101 !important;
}

</style>
