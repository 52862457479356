<template>
  <div class="mbz-16" >
    <CategoriesLateral :drawer="drawer" @updateDrawerState="onUpdateDrawerState" />
    
    <v-app-bar app clipped-left flat color="white" height="60" class="">
      <div class="app_bar_content">
        <div class="d-flex align-center mt-4">
          <img v-if="$vuetify.breakpoint.mdAndDown" src="../assets/menu.svg"  class="mr-4 cursor-pointer" @click.stop="drawer = true">
          <img src="../assets/fullAliflogo.svg" alt="" class="cursor-pointer" @click="toHomePage">
        </div>
        <FavsPanierUser style="" :class="{ 'absolute-fav-panier-userz': !$vuetify.breakpoint.xs }" class="mt-4 mrz-4"/>
      </div>
    </v-app-bar>
    <SearchProducts class="mt-16 mx-auto px-2 search_bar" style=""></SearchProducts>
  </div>
  
</template>

<script>
import { mapGetters } from "vuex";
import SearchProducts from "./SearchProducts.vue";
import FavsPanierUser from "../components/FavsPanierUser";
import utilFunctions from "../helpers/utilFunctions";
import CategoriesLateral from "./CategoriesLateral"
export default {
  data: () => ({
    menu_sub_key: 1,
    showMenuSub: true,
    drawer: false
  }),
  components: {
    FavsPanierUser,
    SearchProducts,
    CategoriesLateral,
  },
  methods: {
    toHomePage() {
      if (this.$route.query.enStock == undefined) {
        if (utilFunctions.isAtleastRoleLevel(50, this.userInfos.roles)) {
          this.$route.query.enStock = false
        } else {
          this.$route.query.enStock = true
        }
      }
      this.$router.push({ path: "/", query: { enStock: this.$route.query.enStock } });
    },
    toShoppingCartPage() {
      this.$router.push({ path: "/panier" });
    },
    // eslint-disable-next-line no-unused-vars
    toProductsPage(category) {
      var menu_subs = document.getElementsByClassName("menu-sub");
      Array.from(menu_subs)
        .forEach(menu_sub => {
          menu_sub.style.visibility = 'hidden'
          menu_sub.style.opacity = '0'
        })
      if (this.$route.query.enStock == undefined) {
        if (utilFunctions.isAtleastRoleLevel(50, this.userInfos.roles)) {
          this.$route.query.enStock = false
        } else {
          this.$route.query.enStock = true
        }
      }
      this.$router.push({
        name: "products",
        query: { categoryID: category.id, enStock: this.$route.query.enStock },
      });

    },
    iconeUrl(product) {
      let url = this.imagesBaseURL;
      let index = "01";
      if (product.imagePreview.startsWith("autre_")) {
        url += "/autre";
        index = product.imagePreview.split("_")[1];
      }
      return (
        url +
        "/" +
        product.reference +
        "/" +
        index +
        ".jpg?" +
        product.imagesUUID
      ); //performance.now()
    },
    setListenersCategoriesHover() {
      // eslint-disable-next-line no-constant-condition
      if (true) {
        console.log('setting listeners hover, cats -> ', this.allCategoriesLevels)
        var menu_main_lis = document.getElementsByClassName("menu-main-li-hover");
        //console.log('menu_main_lis ->', menu_main_lis)
        Array.from(menu_main_lis)
          .forEach(menu_main_li => {
            //console.log('menu_main_li', menu_main_li)
            //console.log('menu_main_li -> ', menu_main_li)
            // eslint-disable-next-line no-unused-vars
            menu_main_li.addEventListener("mouseenter", event => {
              //console.log('entered, children ->', menu_main_li.children)
              var menu_sub = menu_main_li.children[1]
              menu_sub.style.visibility = 'visible'
              menu_sub.style.opacity = '1'
            })

            // eslint-disable-next-line no-unused-vars
            menu_main_li.addEventListener("mouseleave", event => {
              //console.log('mouseleave')
              var menu_sub = menu_main_li.children[1]
              menu_sub.style.visibility = 'hidden'
              menu_sub.style.opacity = '0'
            })
            /*
            // eslint-disable-next-line no-unused-vars
            menu_main_li.addEventListener("mouseout", event => {
              console.log('mouseout')
            })*/
          })
      }
    },
    onUpdateDrawerState(val) {
      console.log('onUpdateDrawerState', val)
      this.drawer = val
    }
  },
  computed: {
    ...mapGetters([
      "majedPanier",
      "shoppingCart",
      "do_animate_panier",
      "allCategoriesLevels",
      'imagesBaseURL',
      'userInfos'
    ]),
  },
  watch: {
    allCategoriesLevels() {
      this.setListenersCategoriesHover()
    }
  },
  updated() {
    this.setListenersCategoriesHover()
  },
  mounted() {
    console.log('mounter header')
    //var menu_subs = document.querySelectorAll("menu-sub-hovered"); 
    this.setListenersCategoriesHover()
  }
};
</script>

<style scoped>

.app_bar {
  
}
.app_bar_content {
  width: 100%;
  display: flex;
  paddingZ: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch; 
}

.logo-top-left {
  margin-left: 30px;
}

@media screen and (min-width: 1380px) {
  .header-container {
    width: 1264px;
    margin-left: auto;
    margin-right: auto;
  }

  .FavsPanierUser {

    margin-right: -80px;
  }

  .logo-top-left {
    margin-left: -30px;
  }
}

/*.logo-top-left {
  position: fixed;
  top: -50px;
  left: 0;
}
   */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 3.5s ease-out;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 0;
}

nav {
  background: #fff;
  /*box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);*/
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  text-align: left;
}

nav li {
  display: inline-block;
  cursor: pointer;
}

nav li:hover {
  /*background: #444;*/
}

nav li:hover {
  color: rgb(253, 26, 22);
}

.menu-main {
  display: flex;
  justify-content: center;
}

ul.menu-main>li.menu-main-li::after {
  content: "";
  position: absolute;
  top: 75%;
  left: 50%;
  border-left: 1px solid rgb(253, 26, 22);
  border-top: 1px solid rgb(253, 26, 22);
  /*border-right: 1px solid rgb(253, 26, 22);
    border-bottom: 1px solid rgb(253, 26, 22);*/
  width: 14px;
  height: 14px;
  margin-left: 11px;
  /*margin-top: 7px;*/
  transform: rotate(45deg);
  background-color: white;
  opacity: 0;
  z-index: 10;
  transition-property: all;
  transition-duration: 0.3s;
}

.no-after::after {
  content: "" !important;
}

ul.menu-main>li:hover::after {
  opacity: 1;
  z-index: 10;
}

.menu-main-liSpe {
  /*padding: 0 1em 0em 1.5em;*/
  margin: 0 1em 0.5em 1.5em;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  position: relative;
}

.menu-main-li {
  /*color: red;*/
  padding: 0 0.5em 1em 0.5em;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  position: relative;
}

@media screen and (max-width: 1800px) {
  .menu-main-li {
    font-weight: 600;
    font-size: 17px;
    padding: 0 0.5em 1em 0.5em;
  }
}

@media screen and (max-width: 1600px) {
  .menu-main-li {
    font-weight: 600;
    font-size: 16px;
    padding: 0 0.5em 1em 0.5em;
  }
}

@media screen and (max-width: 1500px) {
  .menu-main-li {
    font-weight: 600;
    font-size: 15px;
    padding: 0 0.5em 1em 0.5em;
  }
}

@media screen and (max-width: 1400px) {
  .menu-main-li {
    font-weight: 600;
    font-size: 14px;
    padding: 0 0.5em 1em 0.5em;
  }
}

.menu-sub {
  font-weight: initial;
  position: fixed;
  left: 0;
  right: 0;
  margin-top: 14px;
  background: #fff;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition-property: height, opacity, visibility;
  transition-duration: 0.3s, 0.2s, 0s;
  transition-timing-function: linear, linear, linear;
  transition-delay: 0s, 0s, 0.2s;
  color: #000;
  padding: 0 2em;
  border-top: 1px solid rgb(253, 26, 22);
  box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.05);
  z-index: 10;
}

/*
nav li:hover .menu-sub {
  visibility: visible;
  opacity: 1;
}*/
nav li .menu-sub-hovered {
  visibility: visible;
  opacity: 1;
}

.menu-sub li {
  display: block;
}

.menu-sub a:hover {
  text-decoration: underline;
}

.menu-category {
  margin: 0 0 0.25em;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.menu-category:hover {
  color: rgb(253, 26, 22);
}

.scategories-container {
  /*min-width: 190px;*/
  padding: 0 10px 0 10px;
  margin: 0 0 11px 0;
  width: 233px;
  /*min-height: 240px;*/
}

.scategories-container:hover {}

.scategories-ul {
  margin-left: 26px;
  font-size: 16px;
  font-weight: 700;
  word-wrap: break-word;
}

.scategory-li {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  word-wrap: break-word;
}

.scategory-li:hover {
  transform: translateX(5px);
  transition: all 0.4s;
  position: relative;
  z-index: 20;
}

.scategory-li:hover::before {
  content: "/";
  color: rgb(253, 26, 22);
  margin-right: 3px;
  transition: all 0.4s;
  top: 0;
  bottom: 0;
  position: relative;
  z-index: 20;
}

/*
.menu-category::before {
  content: "/";
  color: rgb(253, 26, 22);
  margin-right: 20px;
}*/
.menu-category-before {
  color: rgb(253, 26, 22);
  margin-right: 20px;
}

.header-top {
  display: flex;
  justify-content: center;
}

.v-btn::before {
  background-color: transparent;
}

.FavsPanierUser {
  /*position: absolute;
  right: 50px;*/
  width: 230px;
}

.cursor-pointer {
  cursor: pointer;
}

.pc-header {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
}

.absolute-fav-panier-user {
      position: absolute;
      right:8px;
      top:-10.5px;
    }
.search_bar {

  max-width: 1000px;
}

</style>