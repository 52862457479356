<template>
  <!--
     :class="{'mx-auto': $vuetify.breakpoint.smAndDown}
    -->
  <v-card class="overflow-auto root_card" style="min-height: 600px;">
    <div class="text-right d-flex align-center mr-2 bouton-fermerzz" v-if="$vuetify.breakpoint.smAndDown || true">
      <div class="mr-auto">
        <v-breadcrumbs :items="items">
          <!--<template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>-->
        </v-breadcrumbs>
      </div>
      <v-btn fab="" text="" class="redz--text" @click="closeDialog">
        <img src="../assets/croix.svg" >
      </v-btn>
    </div>
    <div class="column-images mx-auto px-6">
      <div>
        <!-- images -->
        <div v-if="product.nombreImages360 > 0 || product.nombreImagesAutre > 0">
          <!-- images 360 -->
          <div class="image product-360 pr-1" v-show="selectedThumbnailIndex == -1" style="max-widthz: 600px"
            v-if="product.nombreImages360 > 0">
            <a class="Magic360" :href="imagesBaseURL +
              '/' +
              product.reference +
              '/large_01.jpg?' +
              product.imagesUUID
              " :data-options="'filename: {col}.jpg?'+product.imagesUUID+'; columns:' +
                  product.nombreImages360 +
                  '; large-filename: large_{col}.jpg?' + product.imagesUUID +'; autospin:infinite; autospin-speed:7200'
                  ">
              <img style="width: 100%; height: 100%" :src="imagesBaseURL +
                '/' +
                product.reference +
                '/01.jpg?' +
                product.imagesUUID
                " />
            </a>
          </div>
          <!-- images autres -->
          <div v-for="(imageSuffix, i) of imagesSuffixes.slice(
            0,
            product.nombreImagesAutre
          )" :key="i" v-show="selectedThumbnailIndex == i" class="one-of-autresz image product-360 pr-1"
            style="heightz: 456px; max-widthz: 600px">
            <!-- 360 -->
            <a class="Magic360" :href="imagesBaseURL +
              '/autre/' +
              product.reference +
              '/large_' +
              imagesSuffixes[i] +
              '.jpg?' +
              product.imagesUUID
              " :data-options="'filename: ' +
              imagesSuffixes[i] +
              '; columns: 1; large-filename: large_' +
              imagesSuffixes[i]
              ">
              <img style="width: 100%; height: 100%" :src="imagesBaseURL +
                '/autre/' +
                product.reference +
                '/' +
                imagesSuffixes[i] +
                '?' +
                product.imagesUUID
                " />
            </a>
          </div>
        </div>
        <div v-else>
          <img src="../assets/unknown2.jpg" style="width: 100%; height: 100%" />
        </div>
      </div>
      <div class="thumbnails d-flex flex-wrap flex-columnz mt-2 justify-center">
        <!-- thumbnails-->
        <div class="mr-4">
          <!-- thumbnail 360 -->
          <img v-if="product.nombreImages360 > 0" :src="imagesBaseURL +
            '/' +
            product.reference +
            '/' +
            imagesSuffixes[0] +
            '?' +
            product.imagesUUID
            " class="thumbnail" :class="{ 'selected-thumbnail': selectedThumbnailIndex == -1 }"
            @click="selectThumbnail(-1)" />
        </div>
        <div v-for="(imageSuffix, i) of imagesSuffixes.slice(
          0,
          product.nombreImagesAutre
        )" :key="i" class="mr-4">
          <img :src="imagesBaseURL +
            '/autre/' +
            product.reference +
            '/' +
            imagesSuffixes[i] +
            '?' +
            product.imagesUUID
            " class="thumbnail" :class="{ 'selected-thumbnail': selectedThumbnailIndex == i }"
            style="widthz: 100%; heightz: 100%" @click="selectThumbnail(i)" />
        </div>
      </div>
      <div v-if="userInfos.roles.some(x => x.level == 100)" class="d-flex flex-wrap">
        <!-- chaque img 360 -->
        <div class="oneOf360" v-for="(imageSuffix, i) of imagesSuffixes.slice(
          0,
          product.nombreImages360
        )" :key="i">
          <img :src="imagesBaseURL +
            '/' +
            product.reference +
            '/' +
            imageSuffix +
            '?' +
            product.imagesUUID
            " style="width: 100%; height: 100%" :title="i + 1" />
        </div>
      </div>
    </div>
    <div class="column-infos mx-auto mt-10 px-6" style="min-widthZ: 300px; widthZ: 300px;" :class="{ 'text-left': false, 'ml-10': $vuetify.breakpoint.xlAndUp }">
      <!-- infos -->
      <div class="mx-auto">
        <div class="mb-0 mx-auto prod_info_marque" style="display: inline-block">
          <p class="mb-0">
            {{ product.marque }}
          </p>
          <p class="mb-2 prod_info_gamme" >
            {{ product.gamme }}
          </p>
          <p class="mb-4 prod_info_designation">
            {{ product.designation }}
          </p>
        </div>

        <Prices :product="product" :userInfos="userInfos"/>

        <div class="red--text title mb-1" v-if="!product.enStock">
          <v-icon color="red">mdi-close-circle</v-icon> Ce produit n'est plus en stock !
        </div>

        <PlusMoinsPanier :product="product"  class=" mt-4 mx-auto plus_moins_panier_component" @updatedCart="updatedCartHandler"></PlusMoinsPanier>

        <div class="my-10 line"></div>

        <div class="prod_infos_autres_container mb-10">
          <div class="prod_infos_autres_item" v-for="(desc, i) of productProps.filter(x => product[x.objectLabel])" :key="i">
            <div class="prod_infos_autres_item_key">{{ desc.label }}</div>
            <div class="prod_infos_autres_item_value">{{ product[desc.objectLabel] }}</div>
          </div>
        </div>

        <div class="d-flex align-center custom-price-update mx-auto mb-10"
          v-if="hasOneOfRoles(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SHOWROOM'])">
          <v-text-field label="Prix Custom" type="number" v-model="customPrice" style="width:150px; max-width: 150px;"
            class="mr-3" ref="customPriceField" />
          <v-btn :disabled="!product.quantityTmp" fab x-small color="success" @click="updateCustomPrice">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>

        <div class=" line" v-if="product.description"></div>

        <div class="prod_infos_description_container my-10" v-if="product.description">
          <div class="desc_title">Description</div>
          <div class="desc_body">{{ product.description }}</div>
        </div>

        <div class="mx-auto d-table my-10" v-if="product.emplacements">
          <div class="tabKey">emplacements</div>
          <div class="tabValue">{{ product.emplacements.map(x => x.qrcode * 1).sort((a, b) => a - b) }}</div>
        </div>

        <v-snackbar v-model="snackbar" timeout="3000">
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn :color="snackbarIsSuccess ? 'green' : 'red'" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <div v-if="userInfos.roles.some(x => x.level >= 50)"> <!-- form modifs admin                 :rules="doubleRule"
      -->

          <!--<p>Date dernière commande: {{product.stockAddedDate}}</p>
            <p>stock: {{product.stockAddedAmount}}</p>-->
          <div class="mx-auto mt-3 d-table" style="text-align: center;" v-if="product.reassortDate">
            <div>
              <div class="tabKey">Date last commande </div>
              <div class="tabValue red--text">{{ product.reassortDate }}</div>
            </div>
            <div>
              <div class="tabKey">Stock avant réassort </div>
              <div class="tabValue red--text">{{ product.stockAvantReassort }}</div>
            </div>
            <div>
              <div class="tabKey">Stock ajouté </div>
              <div class="tabValue">{{ product.stockApresReassort - product.stockAvantReassort }}</div>
            </div>
            <div>
              <div class="tabKey">stock actuel </div>
              <div class="tabValue">{{ product.stock }}</div>
            </div>
            <div>
              <div class="tabKey">Débit: </div>
              <div class="tabValue">{{ product.stockApresReassort - product.stock }}
                / {{ Math.ceil(((new Date()).getTime() - (new Date(product.reassortDate)).getTime()) / (1000 * 3600 * 24))
                }} J</div>
            </div>
          </div>
          <div></div>
          <v-btn :disabled="!valid" @click="updateProduct">Valider Changements</v-btn>
          <div class="mt-2" style="text-align: left;" v-if="userInfos.roles.some(x => x.level == 100)">
            ref: {{ product.reference }}
          </div>
          <v-form v-model="valid">
            <v-text-field
              v-if="userInfos.roles.some(x => x.level >= 50)"
              v-for="(desc, i) of productPropsToMaj"
              :key="i" v-model="editItem[desc.objectLabel]" :label="desc.label" :rules="desc.rules" />
            <v-select v-if="userInfos.roles.some(x => x.level >= 50)" v-model="editItem.imagePreview"
              :items="getImagePreviewChoices" label="Image Preview">
            </v-select>
            <v-select v-if="userInfos.roles.some(x => x.level >= 50)" v-model="editItem.statut" :items="[0, 1]"
              label="Statut">
            </v-select>
            <v-autocomplete :items="level1Cats" item-text="name" label="category" v-model="selectedLevel1Cat"
              return-object></v-autocomplete>
            <v-autocomplete :items="level2Cats" item-text="name" label="sous category" v-model="selectedLevel2Cat"
              return-object></v-autocomplete>
            <v-autocomplete :items="level3Cats" item-text="name" label="sous category" v-model="selectedLevel3Cat"
              return-object></v-autocomplete>
          </v-form>

        </div>
      </div>
    </div>

  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
//import {Magic360} from '../../public/magic360/magic360Clear'
import {
  mapGetters,
  mapActions,
  mapMutations
} from "vuex";
import PlusMoinsPanier from "../components/PlusMoinsPanier";
import axios from "axios";
import _ from "lodash";
// eslint-disable-next-line no-unused-vars
import utilFunctions from "../helpers/utilFunctions";
//import { Magic360 } from '../../public/magic360/magic360Clear'
import Prices from "./Prices.vue";

export default {
  //props: ["product", 'path'],
  props: {
    product: {
      required: true
    },
    //path: {default: () => []}
  },
  data: () => ({
    snackbar: false,
    imagesSuffixes: [],
    selectedThumbnailIndex: -1,
    valid: true,
    //path: [],
    items: [{
      text: "ACCUEIL",
      disabled: false,
      to: {
        name: "Home"
      },
    },],
    customPrice: undefined,
    selectedLevel1Cat: {},
    selectedLevel2Cat: {},
    selectedLevel3Cat: {},
    doubleRule: [(v) => /^[0-9]*\.?[0-9]+$/.test(v) || "prix custom non valide"],
    productProps: [{
      label: "REFERENCE",
      objectLabel: "reference",
    },
    {
      label: "CODE BARRE",
      objectLabel: "code_barre",
    },
    {
      label: "UNITE DE VENTE",
      objectLabel: "uniteDeVente",
    },
    {
      label: "COLISAGE",
      objectLabel: "colisage",
    },
    {
      label: "SOUS COLISAGE",
      objectLabel: "sous_colisage",
    },
    {
      label: "ORIGINE",
      objectLabel: "origine",
    },
    {
      label: "DIMENSIONS",
      objectLabel: "dimensions",
    },
    {
      label: "VOLUME",
      objectLabel: "volume",
    },
    {
      label: "POIDS",
      objectLabel: "poids",
    },
    {
      label: "COULEUR",
      objectLabel: "couleur",
    },
    {
      label: "MATIERE",
      objectLabel: "matiere",
    },
    {
      label: "TYPE EMBALLAGE",
      objectLabel: "type_emballage",
    },
    {
      label: "STOCK",
      objectLabel: "stock",
    },

    ],
    productPropsToMaj: [
      {
        label: "MARQUE",
        objectLabel: "marque",
      },
      {
        label: "DESIGNATION",
        objectLabel: "designation",
      },
      {
        label: "GAMME",
        objectLabel: "gamme",
      },
      {
        label: "MATIERE",
        objectLabel: "matiere",
      },
      {
        label: "PRIX ACHAT",
        objectLabel: "prixAchat",
        rules: [(v) => /^[0-9]*\.?[0-9]+$/.test(v) || "prix achat non valide"],
      },
      {
        label: "UNITE DE VENTE",
        objectLabel: "uniteDeVente",
        rules: [(v) => /^[0-9]+$/.test(v) || "Unité de vente non valide"],
      },
      {
        label: "STOCK",
        objectLabel: "stock",
        rules: [(v) => /^[0-9]+$/.test(v) || "Stock non valide"],
      },
      {
        label: "COLISAGE",
        objectLabel: "colisage",
      },
      {
        label: "SOUS COLISAGE",
        objectLabel: "sous_colisage",
      },
      {
        label: "ORIGINE",
        objectLabel: "origine",
      },
      {
        label: "DIMENSIONS",
        objectLabel: "dimensions",
      },
      {
        label: "COULEUR",
        objectLabel: "couleur",
      },
      {
        label: "RANG",
        objectLabel: "rang",
      },
      {
        label: "FOURNISSEUR",
        objectLabel: "fournisseur"
      },
      {
        label: "PRIX PROMO",
        objectLabel: "prixPromo",
      },
      {
        label: "PRIX VENTE",
        objectLabel: "prixA",
        rules: [(v) => /^[0-9]+\.?[0-9]*|\.[0-9]+$/.test(v) || "prix de vente non valide"],
      },
    ],
    productPartialPropsToMaj: [
      {
        label: "PRIX VENTE",
        objectLabel: "prixA",
        rules: [(v) => /^[0-9]+\.?[0-9]*|\.[0-9]+$/.test(v) || "prix de vente non valide"],
      },
      {
        label: "PRIX PROMO",
        objectLabel: "prixPromo",
      },
      {
        label: "PRIX ACHAT",
        objectLabel: "prixAchat",
        rules: [(v) => /^[0-9]+\.?[0-9]*|\.[0-9]+$/.test(v) || "prix achat non valide"],
      },
      {
        label: "UNITE DE VENTE",
        objectLabel: "uniteDeVente",
        rules: [(v) => /^[0-9]+$/.test(v) || "Unité de vente non valide"],
      }
    ],

    editItem: {
      designation: "",
      dimensions: "",
      imagePreview: "",
    },
    textRules: [(v) => !!v || "Champ obligatoire"],
    integerRules: [(v) => /^[0-9]*$/.test(v) || "Unité de vente non valide"],
    decimalRules: [
      (v) => /^[0-9]*(\.[0-9]+)?$/.test(v) || "Decimal number required",
    ],
    cartItem: undefined,
    snackbarMessage: '',
    snackbarIsSuccess: true
  }),
  components: {
    PlusMoinsPanier,
    Prices
  },
  computed: {
    ...mapGetters([
      "imagesBaseURL",
      "productDetailsDialog",
      "allCategoriesLevels",
      "getJwToken",
      "apiURL",
      "userInfos",
      'shoppingCart'
    ]),
    getImagePreviewChoices() {
      let choices = [
        ...this.imagesSuffixes
          .slice(0, this.product.nombreImages360)
          .map((x) => x.split(".")[0]),
        ...this.imagesSuffixes
          .slice(0, this.product.nombreImagesAutre)
          .map((x) => x.split(".")[0])
          .map((x) => "autre_" + x),
      ];
      return choices;
    },
    level1Cats() {
      return utilFunctions.findSiblingsByID(
        this.selectedLevel1Cat.id,
        this.allCategoriesLevels
      ).filter(cat => cat.id <= 1000);
    },
    level2Cats() {
      return utilFunctions.findSiblingsByID(
        this.selectedLevel2Cat.id,
        this.allCategoriesLevels
      );
    },
    level3Cats() {
      return utilFunctions.findSiblingsByID(
        this.selectedLevel3Cat.id,
        this.allCategoriesLevels
      );
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedLevel1Cat(val) {
      //this.setParentCategoryID({ parentCategoryID: val.id });
      // eslint-disable-next-line no-constant-condition
      if (this.selectedLevel1Cat.id != this.path[0].id) {
        let selectedLevel2CatTmp = utilFunctions.findCategoryByID(
          this.selectedLevel1Cat.id,
          this.allCategoriesLevels
        );
        if (selectedLevel2CatTmp != null)
          this.selectedLevel2Cat = selectedLevel2CatTmp.childCategories[0];
      }
    },
    // eslint-disable-next-line no-unused-vars
    selectedLevel2Cat(val) {
      // eslint-disable-next-line no-constant-condition
      if (this.selectedLevel2Cat.id != this.path[1].id) {
        let selectedLevel3CatTmp = utilFunctions.findCategoryByID(
          this.selectedLevel2Cat.id,
          this.allCategoriesLevels
        );
        if (selectedLevel3CatTmp != null)
          this.selectedLevel3Cat = selectedLevel3CatTmp.childCategories[0];
      }
    },
  },
  methods: {
    async updateCustomPrice() {
      console.log('updating custom price, cartItem ->', this.cartItem)
      if (this.cartItem) {
        try {
          await this.updateCartItemCustomPrice({ cartItemID: this.cartItem.id, newCustomPrice: this.customPrice })
          this.snackbarMessage = 'Produit mis à jour avec succès.'
          this.snackbarIsSuccess = true
          this.cartItem.product.prixA = parseFloat(this.customPrice)
          this.cartItem.product.enPromo = false //si y a un prix custom y a plus de promo applicable sur le produit
          this.snackbar = true
        } catch (error) {
          console.log('error updating custom price', error)
          this.snackbarIsSuccess = false
          this.snackbarMessage = 'Erreur mise à jour prix'
          this.snackbar = true
        }

      }


    },
    hasOneOfRoles(roles) {
      //console.log('in has one of roles, userInfos ->', this.userInfos)
      return this.userInfos.roles
        .map(x => x.roleName)
        //.split(',')
        .some(role => roles.includes(role))
    },
    async updateProduct() {
      let url = this.apiURL + `/api/updateProduct/`;
      //url += `${this.product.categoryID}`;
      //this.editItem.oldSubCategoryID = this.categoryID; //
      this.editItem.category = {
        id: this.selectedLevel3Cat.id
      };
      try {
        let response = await axios.post(url, this.editItem, {
          params: {},
          headers: {
            Authorization: "Bearer " + this.getJwToken
          },
        });
        console.log("got response -> ", response.data);
        this.snackbarIsSuccess = true
        this.snackbarMessage = 'Produit mis à jour avec succès.'
        this.snackbar = true
      } catch (err) {
        console.log("error updating product ->", err);
        alert("Erreur lors de la mise à jour du produit");
      }
      //dispatch('fetchProductsByCategory', { id: state.categoryID })
      //dispatch('fetchAllProducts')
      //alert("Le produit a été mis à jour");

    },
    ...mapActions(["persistFavoris", 'updateCartItemCustomPrice']),
    ...mapMutations([
      "addFavoris",
      "deleteFavoris",
      "setProductDetailsDialog",
      "setProductDetailsDialogFromSearchBar",
    ]),

    selectThumbnail(index) {
      this.selectedThumbnailIndex = index;
      // eslint-disable-next-line no-undef
      Magic360.start();
    },
    closeDialog() {
      this.$emit('close', this.product.reference)
      //this.setProductDetailsDialog(false);
      //this.setProductDetailsDialogFromSearchBar(false);
    },
    getPerformance() {
      return performance.now();
    },
    toggleFavorite(product) {
      //console.log('fav ->', product.reference)
      if (product.enFavoris) this.deleteFavoris(product);
      else this.addFavoris(product);
      product.enFavoris = !product.enFavoris;
      this.debouncedPersistFavoris();
    },
    updatedCartHandler() {
      if (!this.cartItem && this.hasOneOfRoles(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SHOWROOM'])) {
        let cartItem = this.shoppingCart.find(item => item.product.reference == this.product.reference)
        console.log('in prod details, cartItem ->', cartItem)
        if (cartItem) {
          this.product.quantityTmp = cartItem.quantity
          this.customPrice = cartItem.product.prixA
          this.cartItem = cartItem
          //if(cartItem.customPrice) {
          //this.product.prixA = cartItem.product.prixA
          //}
        }
      }
    },
    setPath() {
      console.log("setting path from details prod, path ->", this.path, ', allCats ->', this.allCategoriesLevels, ', product ->', this.product);
      //if(this.path.length == 0) {
      this.path = [];
      utilFunctions.getCategoriesPath(
        this.product.categoryId,
        this.allCategoriesLevels,
        this.path
      );
      console.log('path after ->', this.path)
      //}
      //console.log('setted path from details prod', this.path)
      this.pathMaj++;
      this.items = [{
        text: "ACCUEIL",
        disabled: false,
        to: {
          name: "Home"
        },
        exact: true,
      },];
      if (this.$route.query.enStock == undefined) {
        if (utilFunctions.isAtleastRoleLevel(50, this.userInfos.roles)) {
          this.$route.query.enStock = false
        } else {
          this.$route.query.enStock = true
        }
      }
      let level = 1;
      for (let cat of this.path) {
        this.items.push({
          text: cat.name,
          disabled: false,
          to: {
            name: "products",
            query: {
              categoryID: cat.id,
              enStock: this.$route.query.enStock
            }
          },
          exact: true,
        });
        level++
        if(level > 2 ) {
          break
        }
      }
      //this.items.push({text: this.product.designation, disabled: true, exact: true})
      //console.log('setted path', this.path)
    },
  },
  created() {
    this.debouncedPersistFavoris = _.debounce(this.persistFavoris, 500);
    if (
      this.product.nombreImages360 < 1 &&
      this.product.nombreImagesAutre > 0
    ) {
      this.selectedThumbnailIndex = 0;
    }
    console.log('created details produit component, prod ->', this.product)


    this.editItem = Object.assign({}, this.product);
    this.editItem.category = {
      id: this.product.categoryId,
    };
    this.setPath();
    console.log('path ->', this.path)
    if (this.path.length == 3) {
      this.selectedLevel1Cat = JSON.parse(JSON.stringify(this.path[0]));
      this.selectedLevel2Cat = JSON.parse(JSON.stringify(this.path[1]));
      this.selectedLevel3Cat = JSON.parse(JSON.stringify(this.path[2]));
    }
    this.updatedCartHandler()
  },
  beforeCreate() { },
  beforeMount() {
    for (let i = 1; i <= 36; i++) {
      let name = i <= 9 ? "0" : "";
      this.imagesSuffixes.push(name + i + ".jpg");
    }
    this.imagesSuffixes.push("360");
  },
  mounted() {
    //console.log('mounted ' + this.getPerformance())
    // eslint-disable-next-line no-undef
    Magic360.start();
    //var x = document.getElementsByClassName("one-of-autres")[0]
    //console.log(x)
    //x.requestFullscreen()
  },

};
</script>

<style scoped>
.prod_info_marque {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.prod_info_gamme {
  color: #A3A3A3;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  text-transform: lowercase;
}
.prod_info_gamme:first-letter {
  text-transform: uppercase;
}

.prod_info_designation {
  color: #171717;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}


.plus_moins_panier_component {
  widthZ: fit-content;
  max-widthz: 200px;
  width: 200px;
}

.line {
  height: 1px;
  background: #E6E6E6;
}

.prod_infos_autres_container {
  margin-topz: 72px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
.prod_infos_autres_item {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
}
.prod_infos_autres_item_key {
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  margin-bottom: 2px;
}
.prod_infos_autres_item_value {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
}
.prod_infos_description_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.prod_infos_description_container .desc_title {
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.prod_infos_description_container .desc_body {
  align-self: stretch;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-height {
  line-height: 0.7em;
  height: 1.4em;
}

.overflow-auto {
  overflow: auto;
}

.column-images {
  max-width: 775px;
  width: fit-content;
}

.thumbnail {
  cursor: pointer;
  transition: all 0.3s;
  width: 64px;
  height: 64px;
  border-radius: 16px;
  border: 1px solid #E6E6E6;
}

.product-360 {
  border-radius: 16px;
}

@media screen and (min-width: 960px) {
  .thumbnail {
    widthz: 85px;
    heightz: 85px;
  }
}

@media screen and (min-width: 1264px) {
  .thumbnail {
    widthz: 95px;
    heightz: 95px;
  }
}

@media screen and (min-width: 1600px) {
  .thumbnail {
    widthz: 115px;
    heightz: 115px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.thumbnail:hover {
  cursor: pointer;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.15);
  transform: translate(-1px, -1px);
}


.selected-thumbnail {
  /*border-style: solid;
    border-color: #29B6F6;
    box-shadow: 0 0 8px 3px #fa9440;*/
    border: 1px solid #5A5A5A;
}

.zoom {
  transition: transform 0.2s;
  /* Animation */
}

.zoom:hover {
  /*transform: scale(1.05);*/
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.oneOf360 {
  width: 115px;
  height: 115px;
}

.tabKey {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: right;
  text-transform: uppercase;
  padding-right: 13px;
  padding-top: 7px;
  display: table-cell;
}

.tabValue {
  display: table-cell;
  text-align: left;
  padding-left: 13px;
  padding-top: 7px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.custom-price-update {
  width: fit-content
}
.root_card {
  border-radius: 16px;
}
</style>