<template>
  <v-navigation-drawer v-model="drawerState" app width="320px" class="drawer_container" style=" text-align: left;">

    <v-btn text class="mt-2 mb-1 close-drawer-btn" @click.stop="drawerState = false">
      <img src="../assets/croix.svg" >
    </v-btn>
    <div class="inner_drawer_container">
      <div class="special_cats" v-if="allCategoriesLevels.length > 2">
        <div class="special_cat_container" @click.stop="toProductsPage(allCategoriesLevels[2])">
          <div class="special_cat_name">{{ allCategoriesLevels[2].name }}</div>
          <div class="arrow-right"><v-icon>mdi-arrow-right</v-icon></div>
        </div>
        <div class="special_cat_container" @click.stop="toProductsPage(allCategoriesLevels[0])">
          <div class="special_cat_name">{{ allCategoriesLevels[0].name }}</div>
          <div class="arrow-right"><v-icon>mdi-arrow-right</v-icon></div>
        </div>
        <div class="special_cat_container" @click.stop="toProductsPage(allCategoriesLevels[1])">
          <div class="special_cat_name">{{ allCategoriesLevels[1].name }}</div>
          <div class="arrow-right"><v-icon>mdi-arrow-right</v-icon></div>
        </div>
      </div>

      <div class="text_categories">Catégories</div>

      <div class="categories_container">

        <div class="category_container" v-for="(category, i) in level1Categories" @click.stop="toProductsPage(category)">
          <img class="cat_icon" :src="imagesBaseURL + '/new_cats_icons/' + category.id + '.svg'" />
          <div class="cat_name">{{ category.name }}</div>
        </div>

      </div>
    </div>
  </v-navigation-drawer>
</template>
  
<script>
import { mapGetters } from "vuex";
import utilFunctions from "../helpers/utilFunctions";
export default {
  props: ['drawer'],
  components: {
  },
  data: () => ({
    showLevel: 1,
    level1Categories: [],
    level2Categories: [],
    level3Categories: [],
    chosenLevel1Cat: { id: 0, name: "" },
    chosenLevel2Cat: { id: 0, name: "" },
    group: null,
    catsToShow: [],
    categories: [
      "6 JOURS",
      "MAISON",
      "BRICOLAGE",
      "MODE",
      "LOISIRS",
      "MARQUES",
      "SERVICES",
    ],
    scategories: ["LOISIRS CREATIFS", "BEAUX-ARTS", "MULTIMEDIA", "PAPETERIE"], // 'LA NICHE', 'JEUX ET JOUET', 'IDEES CADEAUX',],
    sscategories: ["Loisirs créatifs", "Beaux-arts", "Multimédia", "Papeterie"], // 'LA NICHE', 'JEUX ET JOUET', 'IDEES CADEAUX',]
  }),
  methods: {
    toHomePage() {
      this.$router.push({ path: "/" });
    },
    randomCategoryThumbnail() {
      let random = Math.floor(Math.random() * 10) % 2;
      let urlsImages = ["../assets/perceuse.webp", "../assets/mode.webp"];
      return urlsImages[random];
    },
    goToLevel2(category) {
      console.log("going to level2, from ->", category);
      this.showLevel++;
      this.chosenLevel1Cat = { id: category.id, name: category.name };
      this.level2Categories = this.level1Categories.find(
        (x) => x.id == category.id
      ).childCategories;
      console.log("level 2 cats ->", this.level2Categories);
      const categories_lateral_level1 = document.querySelector(
        ".categories-lateral-level1"
      );
      const categories_lateral_level2 = document.querySelector(
        ".categories-lateral-level2"
      );
      categories_lateral_level1.style.marginLeft = "-350px";
      categories_lateral_level2.style.display = "block";
    },
    goToLevel3(category) {
      console.log("going to level3, from ->", category);
      this.showLevel++;
      //console.log('après map -> ', this.level2Categories.map(x => x.childCategories).flat())
      this.level3Categories = this.level2Categories.find(
        (x) => x.id == category.id
      ).childCategories;
      this.chosenLevel2Cat = { id: category.id, name: category.name };
      const categories_lateral_level3 = document.querySelector(
        ".categories-lateral-level3"
      );
      const categories_lateral_level2 = document.querySelector(
        ".categories-lateral-level2"
      );
      categories_lateral_level2.style.marginLeft = "-350px";
      categories_lateral_level3.style.display = "block";
    },
    goToPreviousLevel() {
      const categories_lateral_level1 = document.querySelector(
        ".categories-lateral-level1"
      );
      const categories_lateral_level2 = document.querySelector(
        ".categories-lateral-level2"
      );
      const categories_lateral_level3 = document.querySelector(
        ".categories-lateral-level3"
      );
      switch (this.showLevel) {
        case 2:
          categories_lateral_level1.style.marginLeft = "0";
          categories_lateral_level2.style.display = "none";
          break;
        case 3:
          categories_lateral_level2.style.marginLeft = "0";
          categories_lateral_level3.style.display = "none";
          break;
      }
      this.showLevel = this.showLevel - 1;
    },
    toProductsPage(category) {
      //this.drawer = false
      //console.log('to products, sub -> ', subCategory)
      if (this.getConnected) {
        //this.fetchProductsByCategory({id: subCategory.id})
      }
      if (this.$route.query.enStock == undefined) {
        if (utilFunctions.isAtleastRoleLevel(50, this.userInfos.roles)) {
          this.$route.query.enStock = false
        } else {
          this.$route.query.enStock = true
        }
      }
      this.$router.push({
        name: "products",
        query: { categoryID: category.id, enStock: this.$route.query.enStock },
      });
    },
  },
  computed: {
    ...mapGetters(["allCategoriesLevels", "imagesBaseURL", 'userInfos']),
    categoriesToShow: {
      get() {
        return this.allCategoriesLevels;
      },
      set(cats) { },
    },
    drawerState: {
      get() {
        return this.drawer
      },
      set(val) {
        this.$emit('updateDrawerState', val)
      }
    }
  },
  watch: {
    showLevel(newVal) {
      console.log("showLevel ->", newVal);
    },
    allCategoriesLevels(newVal) {
      this.level1Categories = this.allCategoriesLevels.filter(cat => cat.id < 10);
    },
  },
  mounted() {
    //console.log('mounted, allcatslevels ->', this.allCategoriesLevels)
    this.level1Categories = this.allCategoriesLevels.filter(cat => cat.id < 10);
  },
};
</script>
<style scoped>
/**/
.nav-drawer {

  display: flex;
  widthz: 320px;

  flex-direction: column;
  align-items: center;
  width: 100%;
}

.close-drawer-btn {
  positionZ: fixed;
  topZ: 0;
  leftZ: 7px;
  margin-left: 10px;
}

.drawer_container {
  cursor: pointer;
  overflow: auto;
}
.inner_drawer_container {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  paddingZ: 45px 32px 100px 32px;
  padding: 0px 32px 100px 32px;
}

.special_cats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.special_cat_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.special_cat_name {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 28.8px */
  letter-spacing: -0.72px;
}

.special_cat_icon {}

.text_categories {
  color: #999;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 16.8px */
  letter-spacing: -0.42px;
  text-transform: uppercase;
}

.categories_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.category_container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.cat_icon {
  width: 24px;
  height: 24px;
}

.cat_name {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 28.8px */
  letter-spacing: -0.72px;
}
i {
    color: #000 !important;
}

</style>