var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cursor-pointer product-card",class:{ enstock: _vm.product.enStock, 'prod_in_cart': _vm.product.quantity > 0 },attrs:{"elevation":"0"}},[_c('div',{staticClass:"img_infos_container"},[_c('div',{staticClass:"cursor-pointer imgcontainer",attrs:{"id":_vm.product.reference}},[(_vm.product.nombreImages360 > 0 ||
                _vm.product.nombreImagesAutre > 0
                )?_c('img',{staticClass:"prod-image",class:{ 'border-red': !_vm.product.enStock },attrs:{"src":_vm.imageUrl(_vm.product)},on:{"click":function($event){$event.stopPropagation();return _vm.toProductDetailsPage(_vm.product)},"error":_vm.replaceByDefault}}):_c('img',{staticClass:"prod-image",class:{ 'border-red': !_vm.product.enStock },attrs:{"src":require("../assets/unknown2.jpg")},on:{"click":function($event){return _vm.toProductDetailsPage(_vm.product)},"error":_vm.replaceByDefault}}),_c('div',{staticClass:"top-left prod_ref"},[_c('span',[_vm._v("REF: "+_vm._s(_vm.product.reference))]),(_vm.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_MANAGER']))?_c('div',{staticClass:"red--text contour-text-blanc"},[_vm._v(" "+_vm._s(_vm.product.referenceFournisseur))]):_vm._e()]),(_vm.product.origine)?_c('div',{staticClass:"top-right-left prod_drapeau d-flex align-center"},_vm._l((_vm.product.origine.split(',')
                .filter(x => ['IT', 'ES', 'BE', 'TU', 'PO', 'PA', 'EU', 'PO', 'AL', 'UK', 'RT', 'SL','FR', 'EU', 'RE', 'BR', 'LI', 'EN', 'DA' ].includes(x))
                .map(x => {
                    if(      ['IT', 'ES', 'BE', 'TU', 'PO', 'PA', 'EU', 'PO', 'AL', 'UK', 'RT', 'SL'].includes(x))
                        return 'EU'
                    return x
                })),function(origin,i){return _c('img',{key:i,staticClass:"mr-1",staticStyle:{"width":"30px"},attrs:{"src":_vm.imagesBaseURL+'/drapeaux/' + origin + '.png',"alt":""}})}),0):_vm._e(),_c('div',{staticClass:"bottom-right overline",staticStyle:{"color":"black"}},[(_vm.hasOneOfRoles(['ROLE_ADMIN']))?_c('div',{staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(Math.round((_vm.product.prixA / _vm.product.prixAchat - 1) * 100))+"%")]):_vm._e(),(_vm.hasOneOfRoles(['ROLE_ADMIN']))?_c('div',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(Math.round(_vm.product.prixAchat * 100) / 100)+" €")]):_vm._e(),_c('span',{staticClass:"prod_udv"},[_vm._v("X"+_vm._s(_vm.product.uniteDeVente))])]),(_vm.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SHOWROOM']))?_c('div',{staticClass:"bottom-left overline black--text contour-text-blanc"},[_c('div',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.product.stock))])]):_vm._e(),_c('v-btn',{staticClass:"top-right overline",attrs:{"color":_vm.product.enFavoris ? 'red' : 'black',"text":"","small":"","fab":""},on:{"click":function($event){return _vm.toggleFavorite(_vm.product)}}},[(_vm.product.enFavoris)?_c('v-icon',[_vm._v("mdi-heart")]):_c('v-icon',[_vm._v("mdi-heart-outline")])],1)],1),_c('div',{staticClass:"prod_infos_container"},[_c('div',{staticClass:"marque_dimensions"},[_c('div',{staticClass:"marque"},[_vm._v(_vm._s(_vm.product.marque)+" ")]),(_vm.product.marque && _vm.product.dimensions)?_c('div',{staticClass:"separator"}):_vm._e(),_c('div',{staticClass:"dimensions"},[_vm._v(_vm._s(_vm.product.dimensions))])]),_c('div',{staticClass:"designation"},[_vm._v(_vm._s(_vm.product.designation))]),_c('Prices',{attrs:{"product":_vm.product,"userInfos":_vm.userInfos}})],1)]),_c('PlusMoinsPanier',{staticClass:"plus_moins_panier_component",attrs:{"product":_vm.product}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }