import Vue from 'vue'
import Vuex from 'vuex'
//import { jwt, connected, adminConnected }from '../data/userData.js'
import login from './modules/login'
import inscription from './modules/inscription'
import products from './modules/products'
import orders from './modules/orders'
import panier from './modules/panier'
Vue.use(Vuex) 

export default new Vuex.Store({
  state: {
    apiURL: 'https://api2.aleaf.fr',
    //apiURL: 'http://192.168.1.88:5001',
    drawer: true,
    coordonneesPanier: {},
    do_animate_panier: false,
    audio: new Audio('https://imagesalf.s3.eu-west-3.amazonaws.com/sounds/bip.mp3?sdfml'),
    LOADING: false,
    nbChargement: 0,
    messagesAxios: [],
    showMessagesAxiosSnack: false,
  },
  mutations: {
    toggleDrawer (state, val) {
      console.log('toggling drawer, val -> ', val)
      state.drawer = val
    },
    setCoordonnesPanier(state, val) {
      console.log('setting coords panier ->', val)
      state.coordonneesPanier.x = val.offsetLeft
      state.coordonneesPanier.y = val.offsetTop
    },
    setDo_animate_panier(state, val) {
      state.do_animate_panier = val
    },
    SET_LOADING(state, val) {
      state.LOADING = val
    },
    incrementNbChargement(state) {
      //console.log('incremengting nbChargement')
      state.nbChargement++
    },
    decrementNbChargement(state) {
      
      setTimeout(() => {
        //console.log('decrement nbChargement')
        state.nbChargement--
      }, 0)
      
    },
    addMessageAxios(state, payload) {
      console.log('adding message axios', payload)
      state.messagesAxios.push(payload)
      setTimeout(() => {
        state.messagesAxios.shift()
      }, 6000)
    },
  },
  actions: {
  },
  getters: {
    drawer (state) {return state.drawer},
    apiURL: (state) => state.apiURL,
    coordonneesPanier: state => state.coordonneesPanier,
    do_animate_panier: state => state.do_animate_panier,
    audio: state => state.audio,
    nbChargement: state => state.nbChargement,
    LOADING: state => state.LOADING,
    messagesAxios: state => state.messagesAxios,
    showMessagesAxiosSnack: state => state.messagesAxios.length > 0,
  },
  modules: {
    login,
    inscription,
    products,
    orders,
    panier
  }
})
