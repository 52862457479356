//


//var store = require('../store')
import store from "@/store"
const obj =  {
  findCategoryByID:  (id, categories) => {
    for(let category of categories) {
      if(category.id == id)   return category
      else if(category.childCategories.length > 0) {
        let foundCat = obj.findCategoryByID(id, category.childCategories)
        if(foundCat != null)  return foundCat
      }
    }
    return null
  },
  getSubcatIDs: (category, subcatIDs) => {
    if(category.childCategories.length == 0) {
      subcatIDs.push(category.id)
    } else {
      for(let category of category.childCategories) {
        obj.getSubcatIDs(category, subcatIDs)
      }
    }
  },
  findSiblingsByID:  (id, categories) => {
    for(let category of categories) {
      if(category.id == id)   return JSON.parse(JSON.stringify(categories))
      else if(category.childCategories.length > 0) {
        let foundCat = obj.findSiblingsByID(id, category.childCategories)
        if(foundCat != null)  return foundCat
      }
    }
    return null
  },
  getCategoriesPath: (id, categories, path) => {
    for(let category of categories) {
      let catCopy = JSON.parse(JSON.stringify(category))
      catCopy.childCategories = []
      if(category.id == id) {
        path.push(catCopy)
        return category
      }   
      else if(category.childCategories.length > 0) {
        
        path.push(catCopy)
        let foundCat = obj.getCategoriesPath(id, category.childCategories, path)
        //console.log('foundcat ->', foundCat)
        if(foundCat != null)  return foundCat
        else path.pop()
      }
    }
    return null
  },
  isAtleastRoleLevel: (minLevel, userInfosRoles) => {
    return userInfosRoles
    .map(x => x.level)
    .some(roleLevel => roleLevel >= minLevel)
  },
  hasOneOfRoles: (roles, userInfosRoles) =>{
    //console.log('in has one of roles, userInfos ->', this.userInfos)
    return userInfosRoles
    .map(x => x.roleName)
    //.split(',')
    .some(role => roles.includes(role))
  },
  dateFromTimestam: (timestamp) => {
    var dateFromT = new Date(timestamp);
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var date = dateFromT.toLocaleDateString("fr-FR", options);
    return date;
  },
  imageUrl(product) {
    let url = store.getters.imagesBaseURL;
    let index = product.imagePreview;
    if (product.imagePreview.startsWith("autre_")) {
      url += "/autre";
      index = product.imagePreview.split("_")[1];
    }
    return (
      url +
      "/" +
      product.reference +
      "/" +
      index +
      ".jpg?" +
      product.imagesUUID
    ); //performance.now()
  },
}
export default obj;
//module.exports = obj;