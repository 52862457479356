import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import searchUtil from "../helpers/searchUtil.js";
import {eventBus} from "../main.js";
Vue.use(VueRouter)
  const routes = [
    //{ path: '/', redirect: '/products/10' },
    {
      path: '/profil',
      name: 'Profil',
      component: () => import('../views/Profil.vue'),
    },
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/LoginPage.vue')
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('../views/Products.vue'),
      props: (route) => route.query || {},
    },
    {
      path: '/panier',
      name: 'panier',
      component: () => import('../views/Panier.vue')
    }
    ,
    {
      path: '/account',
      name: 'account',
      component: () => import('../views/Account.vue')
    },  
    {
      path: '/pdfview/:data',
      name: 'pdfview',
      component: () => import('../views/PdfView.vue'),
      props: true
    },
    {
      path: '/favoris',
      name: 'favoris',
      component: () => import('../views/Favoris.vue'),
      props: true
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('../views/mentions/Faq.vue'),
      props: true
    },
    {
      path: '/conditions',
      name: 'conditions',
      component: () => import('../views/mentions/Conditions.vue'),
      props: true
    },
    {
      path: '/mentions',
      name: 'mentions',
      component: () => import('../views/mentions/Mentions.vue'),
      props: true
    },
    {
      path: '/confidentialite',
      name: 'confidentialite',
      component: () => import('../views/mentions/Confidentialite.vue'),
      props: true
    },
    {
      path: '/inactif',
      name: 'inactif',
      component: () => import('../views/CompteInactif.vue'),
    }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
  
})
router.beforeEach(async (to, from, next) => {
  console.log('in beforeEach', from, to )
  if(to.name == 'products') {
    store.commit('setProductDetailsDialogFromSearchBar', false)
    store.commit('setProductDetailsDialogFromFavoris', false)
  }
  if (!store.getters.userInfos && to.name !== 'Login' && to.name !== 'Home') {
    console.log('in router, myinfos not defined yet, fetching')
    try {
      await store.dispatch('fetchMyInfos')
      console.log('in router, fetched my infos')

      next()
    } catch (err) {
      console.log(err)
    }
  } else {
    next()
  }
})
//searchTerm=balai
router.afterEach((to, from) => {
  console.log('in after each')
  if(to.query.searchTerm == undefined && from.query.searchTerm != undefined) {
    searchUtil.onCloseDivSearchResluts()
  } else if (to.query.searchTerm != undefined && from.query.searchTerm == undefined) {
    searchUtil.onOpenDivSearchResluts()
  }
  
})

export default router