import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import interceptorsSetup from './helpers/interceptors.js'
import { initializeApp } from 'firebase/app';

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCpLFiKYdDPutjN1rSuM2vOMAXRHEbNB1A",
  authDomain: "notifs-aleaf.firebaseapp.com",
  projectId: "notifs-aleaf",
  storageBucket: "notifs-aleaf.appspot.com",
  messagingSenderId: "1042937717963",
  appId: "1:1042937717963:web:043d79b6f186ea3cc3274e",
  measurementId: "G-HXLD0HDLGQ"
};

initializeApp(firebaseConfig);


//
Vue.config.productionTip = false
interceptorsSetup()

export const eventBus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
